import {
    AuthFlowType,
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { signUp } from 'aws-amplify/auth';

import { generatePassword, getAxios } from '../../../core/utils';

import { SignUpParameters } from './types';

export const getUserTokenId = async (username: string, password: string) => {
    const client = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION,
    });
    const command = new InitiateAuthCommand({
        ClientId: process.env.REACT_APP_AWS_CLIENT_ID,
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        AuthParameters: { USERNAME: username, PASSWORD: password },
    });
    const { AuthenticationResult } = await client.send(command);
    return AuthenticationResult?.IdToken;
};

export const handleSignUp = async (
    {
        firstName,
        lastName,
        email,
        phoneNumber,
        country,
        workspaceName,
    }: SignUpParameters,
    onSuccess?: () => void,
    onFailure?: (error: Error) => void
) => {
    try {
        const password = generatePassword();
        const { isSignUpComplete, userId } = await signUp({
            username: email,
            password,
            options: {
                userAttributes: {
                    given_name: firstName,
                    family_name: lastName,
                    email,
                    phone_number: phoneNumber,
                },
            },
        });
        if (isSignUpComplete) {
            const tokenId = await getUserTokenId(email, password);
            const axios = await getAxios(tokenId);
            const { data } = await axios.post('/api/User/SignUp', {
                workspaceName,
                country,
                cognitoSub: userId,
                firstName,
                lastName,
                email,
                phoneNumber,
            });
            if (!data.successful)
                throw new Error(
                    'Something went wrong. Please, try again later'
                );
            onSuccess?.();
        } else throw new Error('Something went wrong. Please, try again later');
    } catch (error) {
        if (error instanceof Error) onFailure?.(error);
    }
};
