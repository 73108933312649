import {
    fetchUserAttributes,
    getCurrentUser as getCurrentAuthenticatedUser,
} from '@aws-amplify/auth';
import {UserAttributes, Workspace} from './types';
import {getAxios} from "../../utils";
import {UserRole} from "../../enums/userRole";

export const getCurrentUser = async () => {
    try {
        return await getCurrentAuthenticatedUser();
    } catch {
        return undefined;
    }
};

export const getCurrentUserAttributes = async (): Promise<
    UserAttributes | undefined
> => {
    try {
        const axios = await getAxios();
        const amplifyUserAttributes = await fetchUserAttributes();
        const { data: userAttributes } = await axios.get(
            '/api/User/GetLoggedInUser'
        );
        return {
            ...amplifyUserAttributes,
            ...(userAttributes.successful && {
                id: userAttributes.results.id,
                stripeId: userAttributes.results.stripeDetails?.stripeId,
                systemRole: userAttributes.systemRole,
                roles: [
                    ...userAttributes.results.workspaceUserRoles,
                    ...userAttributes.results.ownedWorkspaces.map((w: Workspace)=>({
                        workspaceId: w.id,
                        workspaceName: w.name,
                        userId: w.userId,
                        role: UserRole.ADMIN,
                        isActive: true,
                        integrations: w.integrations
                    }))
                ],
                //subscription: userAttributes.subscriptionData,
               // integrations: userAttributes.integrations,
                workspaces: userAttributes.results.ownedWorkspaces,
            })
        };
    } catch {
        return undefined;
    }
};
