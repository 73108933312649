const lowerCasedAlphabets = [...'abcdefghijklmnopqrstuvwxyz'.split('')];

const upperCasedAlphabets = lowerCasedAlphabets.map((alphabet) =>
    alphabet.toUpperCase()
);

const numbers = [...'1234567890'.split('').map((num) => +num)];

const symbols = [...'!#$%&()*+,-.:;<=>?@^_`{|}~'.split('')];

const getRandomNumber = (max: number) => Math.floor(Math.random() * max);
const generatePassword = (length = 16): string => {
    const generatedPassword = [];

    for (let i = 0; i < length; i++) {
        generatedPassword.push(
            upperCasedAlphabets[getRandomNumber(upperCasedAlphabets.length)]
        );
        generatedPassword.push(
            lowerCasedAlphabets[getRandomNumber(lowerCasedAlphabets.length)]
        );
        generatedPassword.push(numbers[getRandomNumber(numbers.length)]);
        generatedPassword.push(symbols[getRandomNumber(symbols.length)]);
    }

    return generatedPassword.slice(0, length).join('');
};

export default generatePassword;
