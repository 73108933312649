import { object, string } from 'yup';
import {BeMessage} from "../../../core/beUi";


const validationSchema = object().shape({
    username: string()
        .email(() => <BeMessage severity="error" text="Email is invalid" />)
        .required(() => (
            <BeMessage severity="error" text="Email is required" />
        )),
});

export default validationSchema;