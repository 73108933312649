import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
    isPublic?: boolean;
    isAuthorized?: boolean;
}
const ProtectedRoutes: React.FC<ProtectedRouteProps> = ({ isPublic, isAuthorized }) => {
    return (isPublic || isAuthorized) ? <Outlet /> : <Navigate to='/sign-in' />
}

export default ProtectedRoutes;