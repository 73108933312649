import React, {useContext} from 'react';
import {BeLink} from "../../core/beUi";
import {UserContext} from "../../core/contexts";
import {Navigate} from "react-router-dom";

function LandingPage() {

    const { currentUser, isLoading } = useContext(UserContext);

    if(currentUser){
        return (<Navigate to="/dashboard" />);
    } else {
        return (
            <div className="flex w-full flex-col items-center justify-center gap-x-10 gap-y-6 sm:flex-row">
                <BeLink
                    to="/sign-in"
                    variant="outlined"
                    size="lg"
                    fullWidth
                    className="max-w-72 shrink border-secondary sm:basis-72"
                >
                    Sign In
                </BeLink>
                <BeLink
                    to="/sign-up"
                    variant="outlined"
                    size="lg"
                    fullWidth
                    className="max-w-72 shrink border-secondary sm:basis-72"
                >
                    Sign Up
                </BeLink>
            </div>
        );
    }
}

export default LandingPage;
