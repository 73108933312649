import { CircleNotch } from '@phosphor-icons/react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import { BeButton, BeInputText } from '../../../../core/beUi';
import { StepProps } from '../types';
import validationSchema from './validationSchema';
import {Link} from "react-router-dom";

const StepTwo = <T extends FormikValues>({
                                             loading,
                                             initialValues,
                                             handleSubmit,
                                             handleBack,
                                         }: StepProps<T>) => (
    <Formik<T>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
    >
        {({ values, handleChange }) => (
            <Form>
                <div className="flex flex-col items-center gap-y-6">
                    <div className="w-full max-w-lg rounded-sm bg-white">
                        <h3 className="border-b border-brandGray-5 p-6 pb-5 text-center text-2xl font-bold sm:p-8 sm:pb-6">
                            Create a workspace for your agency
                        </h3>
                        <div className="flex flex-col items-stretch justify-start gap-y-4 px-6 py-5 sm:px-8 sm:py-6">
                            <div className="flex flex-col gap-1">
                                <label className="text-sm">
                                    Agency Name
                                </label>
                                <BeInputText
                                    autoComplete="off"
                                    className="w-full"
                                    placeholder="Enter agency name"
                                    name="workspaceName"
                                    values={values}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="workspaceName" />
                            </div>
                        </div>
                        <div className="flex flex-col justify-stretch gap-x-6 gap-y-4 border-t border-brandGray-5 p-6 pt-5 sm:flex-row sm:p-8 sm:pt-6">
                            <BeButton
                                type="button"
                                size="lg"
                                variant="outlined"
                                fullWidth
                                onClick={() => handleBack(values)}
                            >
                                Back
                            </BeButton>
                            <BeButton
                                size="lg"
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircleNotch
                                        size="1.75rem"
                                        className="animate-spin"
                                    />
                                ) : (
                                    'Finish'
                                )}
                            </BeButton>
                        </div>
                    </div>
                    <p className="pb-12">
                        If you already have an account,{' '}
                        <Link
                            to="/sign-in"
                            className="underline underline-offset-4"
                        >
                            Sign In
                        </Link>
                    </p>
                </div>
            </Form>
        )}
    </Formik>
);

export default StepTwo;
