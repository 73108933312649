import {BeButton} from "../../../core/beUi";
import {ArrowLeft, AsteriskSimple} from "@phosphor-icons/react";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "primereact/skeleton";


const UserEditLoading = () => {

    const navigate = useNavigate();

    const goBack = () => {
        navigate('/users');
    }

    return (
        <div className="flex flex-col">
            <BeButton
                onClick={() => goBack()}
                size="sm"
                variant="ghost"
                className="mb-6 self-start text-blue-500"
            >
                <ArrowLeft size="1.75rem"/>
                Back
            </BeButton>
            <div className="mb-4 flex items-center justify-between gap-6">
                <Skeleton height="2rem" width="20rem" />
                <Skeleton height="2.5rem" width="10rem" />
            </div>
            <div className="flex flex-col gap-7 lg:flex-row lg:items-start">
                <div className="grow flex-col items-start gap-6 rounded-xl bg-white p-8 shadow-sm">

                                <div className="flex flex-col gap-y-8">
                                    <div className="flex flex-col gap-5">
                                        <h3 className="text-2xl font-bold">
                                            User Details
                                        </h3>
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col justify-stretch gap-4 sm:flex-row">
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        First Name
                                                    </label>
                                                    <Skeleton height="2rem"/>
                                                </div>
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Last Name
                                                    </label>
                                                    <Skeleton height="2rem"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Email address
                                            </label>
                                            <Skeleton height="2rem"/>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Country
                                            </label>
                                            <Skeleton height="2rem"/>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col gap-4 sm:flex-row">
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Mobile phone number
                                                    </label>
                                                    <Skeleton height="2rem"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Role
                                            </label>
                                            <Skeleton height="2rem"/>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5">
                                        <h3 className="text-2xl font-bold">
                                            Threshold / Bonus
                                        </h3>
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-col justify-stretch gap-4 sm:flex-row">
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Threshold
                                                    </label>
                                                    <Skeleton height="2rem" />
                                                </div>
                                                <div className="flex grow flex-col gap-1">
                                                    <label className="text-sm">
                                                        Bonus
                                                    </label>
                                                    <Skeleton height="2rem" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Skeleton height="2rem" width="10rem" />
                                </div>

                </div>
                <div className="flex-col items-start gap-6 rounded-xl bg-white p-8 shadow-sm">
                    <div className="flex flex-col gap-4">
                        <h3 className="text-2xl font-bold">Subscription FAQ</h3>
                        <ul className="flex flex-col gap-y-2">
                            <li className="flex items-center gap-x-1.5 leading-5">
                                <AsteriskSimple
                                    size="1.5rem"
                                    weight="bold"
                                    className="shrink-0"
                                />
                                Each user is $19.90 per month
                            </li>
                            <li className="flex items-center gap-x-1.5 leading-5">
                                <AsteriskSimple
                                    size="1.5rem"
                                    weight="bold"
                                    className="shrink-0"
                                />
                                Add and remove users any time, no lock-in
                                contracts
                            </li>
                            <li className="flex items-center gap-x-1.5 leading-5">
                                <AsteriskSimple
                                    size="1.5rem"
                                    weight="bold"
                                    className="shrink-0"
                                />
                                New users will be billed on a pro-rata basis
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserEditLoading;