import { CircleNotch } from '@phosphor-icons/react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import { Dropdown } from 'primereact/dropdown';

import { BeButton, BeInputText, BeLink } from '../../../../core/beUi';

import { StepProps } from '../types';

import validationSchema from './validationSchema';
import {Image} from "primereact/image";
import {Link} from "react-router-dom";

const countries = [
    { label: 'Australia', value: 'AU', icon: '/icons/australia.svg' },
    { label: 'New Zealand', value: 'NZ', icon: '/icons/new-zealand.svg' },
];

const countryCodes = [
    { label: '+61', value: '+61', icon: '/icons/australia.svg' },
    { label: '+64', value: '+64', icon: '/icons/new-zealand.svg' },
];

const selectedCountryCodeTemplate = (option: any, props: any) => {
    if (option) {
        return (
            <div className="flex items-center justify-start gap-2">
                <Image
                    alt={option.value}
                    src={option.icon}
                    width="16"
                    height="16"
                />
                <div>{option.label}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

const countryCodeOptionTemplate = (option: any) => {
    return (
        <div className="flex items-center justify-start gap-2">
            <Image
                alt={option.value}
                src={option.icon}
                width="24"
                height="24"
            />
            <div>{option.label}</div>
        </div>
    );
};

const StepOne = <T extends FormikValues>({
             loading,
             initialValues,
             handleSubmit,
         }: StepProps<T>) => (
    <Formik<T>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
    >
        {({ values, setFieldValue, handleChange }) => (
            <Form autoComplete="off">
                <div className="flex flex-col items-center gap-y-6">
                    <div className="w-full max-w-lg rounded-sm bg-white">
                        <h3 className="border-b border-brandGray-5 p-6 pb-5 text-center text-2xl font-bold sm:p-8 sm:pb-6">
                            Sign Up
                        </h3>
                        <div className="flex flex-col items-stretch justify-start gap-y-4 px-6 py-5 sm:px-8 sm:py-6">
                            <div className="flex flex-col gap-1">
                                <div className="flex flex-col justify-stretch gap-4 sm:flex-row">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-sm">
                                            First Name
                                        </label>
                                        <BeInputText
                                            className="w-full"
                                            placeholder="Enter first name"
                                            name="firstName"
                                            values={values}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-sm">
                                            Last Name
                                        </label>
                                        <BeInputText
                                            className="w-full"
                                            placeholder="Enter last name"
                                            name="lastName"
                                            values={values}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <ErrorMessage name="firstName"/>
                                <ErrorMessage name="lastName"/>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label className="text-sm">Email address</label>
                                <BeInputText
                                    className="w-full"
                                    placeholder="Enter email address"
                                    name="email"
                                    type="email"
                                    values={values}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="email"/>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label className="text-sm">Country</label>
                                <Dropdown
                                    name="country"
                                    value={values['country']}
                                    onChange={(e) =>
                                        setFieldValue('country', e.value)
                                    }
                                    options={countries}
                                    itemTemplate={countryCodeOptionTemplate}
                                    valueTemplate={selectedCountryCodeTemplate}
                                    optionLabel="label"
                                    placeholder="Choose Country"
                                    pt={{
                                        root: {
                                            className:
                                                'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none',
                                        },
                                        input: {
                                            className:
                                                'py-1.5 px-2 text-sm text-secondary',
                                        },
                                        trigger: {
                                            className:
                                                'text-secondary-light w-auto py-1.5 px-2',
                                        },
                                        item: {
                                            className:
                                                'text-secondary aria-selected:bg-primary-light p-2',
                                        },
                                        list: {
                                            className: 'py-2',
                                        },
                                    }}
                                />
                                <ErrorMessage name="country"/>
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="flex flex-col gap-4 sm:flex-row">

                                    <div className="flex grow flex-col gap-1">
                                        <label className="text-sm">
                                            Mobile phone number
                                        </label>
                                        <BeInputText
                                            className="w-full"
                                            placeholder="Enter phone number"
                                            name="phoneNumber"
                                            values={values}
                                            onChange={handleChange}
                                            disabled={!values.country}
                                        />
                                    </div>
                                </div>
                                <ErrorMessage name="phoneNumber"/>
                            </div>

                        </div>
                        <div
                            className="flex flex-col justify-stretch gap-x-6 gap-y-4 border-t border-brandGray-5 p-6 pt-5 sm:flex-row sm:p-8 sm:pt-6">
                            <BeLink
                                to="/sign-in"
                                size="lg"
                                variant="outlined"
                                fullWidth
                            >
                                Back
                            </BeLink>
                            <BeButton
                                size="lg"
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircleNotch
                                        size="1.75rem"
                                        className="animate-spin"
                                    />
                                ) : (
                                    'Continue'
                                )}
                            </BeButton>
                        </div>
                    </div>
                    <p className="pb-12">
                        If you already have an account,{' '}
                        <Link
                            to="/sign-in"
                            className="underline underline-offset-4"
                        >
                            Sign In
                        </Link>
                    </p>
                </div>
            </Form>
        )}
    </Formik>
);

export default StepOne;
