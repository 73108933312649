import { SetupIntent } from '@stripe/stripe-js/dist/api/setup-intents';
import { useCallback, useEffect, useState } from 'react';
import { ArrowLeft, WarningCircle } from '@phosphor-icons/react';
import { ProgressSpinner } from 'primereact/progressspinner';

import { getStripe } from '../../../core/utils';
import { BeLink } from '../../../core/beUi';
import {useSearchParams} from "react-router-dom";
import {Image} from "primereact/image";

const BillingVerifyPage = () => {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState<SetupIntent.Status>();
    const clientSecret = searchParams.get('setup_intent_client_secret');

    const getSetupIntent = useCallback(async () => {
        try {
            const stripe = (await getStripe())!;
            if (!clientSecret) return;
            const { setupIntent } =
                await stripe.retrieveSetupIntent(clientSecret);
            setStatus(setupIntent?.status);
        } catch (err) {
            console.log(err);
        }
    }, [clientSecret]);

    useEffect(() => {
        getSetupIntent();
    }, [getSetupIntent]);

    return status ? (
            <div className="flex flex-col gap-y-12">
            <BeLink
                to="/dashboard"
                size="sm"
                variant="ghost"
                className="self-start text-blue-500"
            >
             <ArrowLeft size="1.75rem" />
            Dashboard
            </BeLink>
            <div className="mx-auto flex max-w-2xl flex-col items-center gap-y-12 text-center">
    {status === 'succeeded' ? (
        <>
            <p className="text-2xl font-bold text-secondary-light">
            Payment Method Verified Successfully
    </p>
    <Image
    alt="Get Started"
    src="/checkout-success.svg"
    width="500"
    height="520"
    />
    </>
) : (
        <>
            <p className="text-2xl font-bold text-secondary-light">
            Sorry, we couldn&apos;t process your payment method.
    <br />
    Please try again later or try another one.
    </p>
    <WarningCircle size="10rem" className="text-red-600" />
        </>
)}
    </div>
    </div>
) : (
        <ProgressSpinner />
    );
};

export default BillingVerifyPage;
