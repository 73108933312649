import { Amplify } from 'aws-amplify';
import { ReactNode } from 'react';

import config from '../../../amplifyconfiguration.json';

Amplify.configure(config, { ssr: false });

const AmplifyProvider = ({
    children,
}: Readonly<{
    children: ReactNode;
}>) => <>{children}</>;

export default AmplifyProvider;
