import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {PrimeReactProvider} from "primereact/api";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import './primereact-custom.css';
import {
    AmplifyProvider,
    ConnectedAppsContextProvider,
    ToastContextProvider,
    UserContextProvider
} from "./core/contexts";
import {Provider} from "react-redux";
import {store} from "./core/state/store";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

      <Provider store={store}>
          <PrimeReactProvider>
              <AmplifyProvider>
                  <ToastContextProvider>
                      <UserContextProvider>
                          <ConnectedAppsContextProvider>
                              <BrowserRouter>
                                  <App />
                              </BrowserRouter>
                          </ConnectedAppsContextProvider>
                      </UserContextProvider>
                  </ToastContextProvider>
              </AmplifyProvider>
          </PrimeReactProvider>
      </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
