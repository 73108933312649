import {AppDispatch} from "../store";
import {
    USERS_LOAD,
    USERS_LOADED,
    USERS_LOADED_ERROR,
    USERS_SELECT_USER, USERS_SELECTED_USER,
    USERS_SELECTED_USER_ERROR
} from "./usersActions";
import {getAxios} from "../../utils";
import {WorkspaceRole} from "../../contexts/User/types";


export function getUsers(workspaceId: number, pageSize = 10, pageNumber = 1, search = '') {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: USERS_LOAD });

        const axios = await getAxios();
        return axios.get(
            '/api/User/GetWorkspaceUsers', {
                params: {
                    workspaceId,
                    pageNumber,
                    pageSize,
                    searchQuery: search
                }
            }
        )
            .then((r) => {
                dispatch({
                            type: USERS_LOADED,
                            users: r.data.results,
                            totalUsers: r.data.totalRecords
                        });
            })
            .catch((e) => dispatch({ type: USERS_LOADED_ERROR, error: e }));
    };
}

export function getUser(userId: number, selectedWorkspace: WorkspaceRole) {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: USERS_SELECT_USER });
        const axios = await getAxios();
        return axios.get(
            '/api/User/GetWorkspaceUserById', {
                params: {
                    userId,
                    workspaceId: selectedWorkspace.workspaceId
                }
            }
        )
            .then((r) => {
                dispatch({
                    type: USERS_SELECTED_USER,
                    user: r.data.results
                });
            })
            .catch((e) => dispatch({ type: USERS_SELECTED_USER_ERROR, error: e }));
    }
}