import Logo from "./common/logo/logo";
import {BeToast} from "../core/beUi";
import Footer from "./common/footer/footer";
import {useContext} from "react";
import {Outlet} from "react-router-dom";
import {ToastContext} from "../core/contexts";

const AuthLayout = () => {
    const toastRef = useContext(ToastContext);
    return (
        <>
        <BeToast ref={toastRef} />
        <div className="flex flex-1 flex-col items-stretch bg-primary">
            <div className="flex flex-1 flex-col items-center justify-center">
                <div className="max-w-80 p-8">
                    <Logo />
                </div>
                <div className="flex w-full flex-col items-center justify-center">
                    <Outlet />
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
}

export default AuthLayout;