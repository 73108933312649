import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {getAxios} from "../utils";


export type UseApiReturnType<T> = {
    data: T | null;
    loading: boolean;
    error: Error | null;
    refetch: () => void;
};

const useApi = <T = any, D = any>(
    config: AxiosRequestConfig<D>,
    initialExecution = true
): UseApiReturnType<T> => {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [canRequest, setCanRequest] = useState(initialExecution);
    const [refetchKey, setRefetchKey] = useState(uuid());

    const makeRequest = useCallback(async () => {
        const axios = await getAxios();
        setLoading(true);
        try {
            const { data } = await axios.request<T, AxiosResponse<T>, D>(
                config
            );
            setData(data);
            setError(null);
        } catch (err) {
            setError(err as Error);
        }
        setLoading(false);
    }, [config]);

    useEffect(() => {
        canRequest && makeRequest();
    }, [makeRequest, refetchKey, canRequest]);

    const refetch = useCallback(() => {
        canRequest ? setRefetchKey(uuid()) : setCanRequest(true);
    }, [canRequest]);

    return { data, loading, error, refetch };
};

export default useApi;
