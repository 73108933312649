import {CalendarBlank} from "@phosphor-icons/react";
import {Dropdown} from "primereact/dropdown";
import {useState} from "react";

const FrequencyPicker = () => {
    const [frequency, setFrequency] = useState( { name: 'Quarterly', code: 1 });

    const frequencies = [
        { name: 'Monthly', code: 0 },
        { name: 'Quarterly', code: 1 },
        { name: '6 Months', code: 2 },
        { name: 'Yearly', code: 3 },
    ];

    const selectedFrequencyTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex items-center gap-x-2">
                    <CalendarBlank size={24}/>
                    <div>{option.name}</div>
                </div>
            );
        }

        return  <div className="flex items-center gap-x-2">
            <CalendarBlank size={32}/>
            <span>{props.placeholder}</span>
        </div>;
        
            };

            return (
        <div className="flex flex-row items-center gap-x-2">
            <Dropdown value={frequency}
                      onChange={(e) => setFrequency(e.value)}
                      valueTemplate={selectedFrequencyTemplate}
                      options={frequencies}
                      optionLabel="name"
                      placeholder="Select a Frequency"
                      pt={{
                          root: {
                              className:
                                  'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none',
                          },
                          input: {
                              className:
                                  'py-1.5 px-2 text-sm text-secondary',
                          },
                          trigger: {
                              className:
                                  'text-secondary-light w-auto py-1.5 px-2',
                          },
                          item: {
                              className:
                                  'text-secondary aria-selected:bg-primary-light p-2',
                          },
                          list: {
                              className: 'py-2',
                          },
                      }}/>
        </div>
    );
}

export default FrequencyPicker;