import { isAxiosError } from 'axios';

const getErrorMessage = (
    error: unknown,
    fallback?: 'Something went wrong. Please, try again later.'
) => {
    if (isAxiosError(error)) {
        if (error.response) return error.response.data.responseMessage;
        return error.message;
    }
    if (error instanceof Error) return error.message;
    return fallback;
};

export default getErrorMessage;
