import { object, string } from 'yup';
import {BeMessage} from "../../../core/beUi";


const jobAdderValidationSchema = object().shape({
    clientId: string().required(() => (
        <BeMessage severity="error" text="Client id is required" />
    )),
    clientSecret: string().required(() => (
        <BeMessage severity="error" text="Client secret is required" />
    )),
});

export default jobAdderValidationSchema;
