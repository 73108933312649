export type LogoProps = {
    variant?: 'light' | 'dark';
    iconOnly?: boolean;
};

const Logo = ({ variant = 'dark', iconOnly = false }: LogoProps) => {
    const logoTextClassName =
        variant === 'dark' ? 'fill-secondary' : 'fill-white';
    const logoIconClassName =
        variant === 'dark' ? 'fill-secondary' : 'fill-primary';
    return iconOnly ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 41 43"
            fill="none"
        >
            <rect
                x="11.2852"
                y="-3.10498"
                rx="12"
                width="35.4893"
                height="38.4468"
                transform="rotate(22.5113 11.2852 -3.10498)"
                className={logoIconClassName}
            />
            <path
                d="M34.5488 11C35.2492 11 35.7326 11.7016 35.4832 12.3561L28.2425 31.3561C28.0948 31.7438 27.7229 32 27.3081 32H21.2756C20.7333 32 20.2898 31.5678 20.2759 31.0257L19.9942 20.0763L15.9651 31.3369C15.8228 31.7346 15.446 32 15.0236 32H8.98381C8.4506 32 8.01118 31.5816 7.98501 31.0491L7.05154 12.0491C7.02349 11.4782 7.47878 11 8.05034 11H12.6345C13.1983 11 13.6507 11.4659 13.634 12.0295L13.274 24.2288L17.8855 11.6557C18.0299 11.2618 18.4048 11 18.8243 11H23.9092C24.4476 11 24.8893 11.4262 24.9086 11.9642L25.3481 24.2288L29.1211 11.7114C29.2485 11.289 29.6375 11 30.0786 11H34.5488Z"
                className="fill-white"
            />
        </svg>
    ) : (
        <svg
            width="100%"
            viewBox="0 0 350 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M347.06 37.3292C347.06 38.1179 347.011 38.9395 346.912 39.7939H327.836C327.968 41.5027 328.51 42.8171 329.463 43.7372C330.449 44.6245 331.648 45.0681 333.061 45.0681C335.164 45.0681 336.627 44.1809 337.448 42.4064H346.419C345.959 44.2137 345.121 45.8404 343.906 47.2863C342.723 48.7322 341.227 49.8659 339.42 50.6875C337.613 51.509 335.592 51.9198 333.357 51.9198C330.662 51.9198 328.263 51.3447 326.16 50.1945C324.057 49.0444 322.414 47.4013 321.231 45.2653C320.048 43.1293 319.457 40.6318 319.457 37.7729C319.457 34.9139 320.032 32.4164 321.182 30.2804C322.365 28.1444 324.008 26.5014 326.111 25.3512C328.214 24.2011 330.629 23.626 333.357 23.626C336.019 23.626 338.385 24.1846 340.455 25.3019C342.525 26.4192 344.136 28.013 345.286 30.0833C346.469 32.1535 347.06 34.5689 347.06 37.3292ZM338.434 35.1111C338.434 33.6652 337.941 32.515 336.955 31.6606C335.969 30.8062 334.737 30.379 333.258 30.379C331.845 30.379 330.646 30.7898 329.66 31.6113C328.707 32.4329 328.116 33.5995 327.886 35.1111H338.434Z"
                className={logoTextClassName}
            />
            <path
                d="M309.589 33.7798C311.594 34.207 313.204 35.2093 314.42 36.7867C315.636 38.3312 316.244 40.1057 316.244 42.1103C316.244 45.0021 315.225 47.3024 313.187 49.0112C311.183 50.6871 308.373 51.5251 304.758 51.5251H288.64V16.9219H304.216C307.732 16.9219 310.476 17.727 312.448 19.3372C314.453 20.9474 315.455 23.1327 315.455 25.8931C315.455 27.9305 314.913 29.6229 313.828 30.9702C312.777 32.3175 311.364 33.2541 309.589 33.7798ZM297.069 30.9209H302.59C303.97 30.9209 305.021 30.6251 305.744 30.0336C306.5 29.4093 306.878 28.5056 306.878 27.3226C306.878 26.1395 306.5 25.2358 305.744 24.6115C305.021 23.9871 303.97 23.6749 302.59 23.6749H297.069V30.9209ZM303.28 44.7227C304.693 44.7227 305.777 44.4106 306.533 43.7862C307.322 43.129 307.716 42.1924 307.716 40.9765C307.716 39.7607 307.305 38.8077 306.484 38.1176C305.695 37.4275 304.594 37.0824 303.181 37.0824H297.069V44.7227H303.28Z"
                className={logoTextClassName}
            />
            <path
                d="M265.635 23.626C267.574 23.626 269.266 24.0203 270.712 24.809C272.191 25.5977 273.324 26.6328 274.113 27.9144V24.0203H282.542V51.4761C282.542 54.0065 282.033 56.2904 281.014 58.3278C280.028 60.398 278.5 62.0411 276.43 63.257C274.392 64.4729 271.846 65.0808 268.789 65.0808C264.715 65.0808 261.412 64.1114 258.882 62.1726C256.351 60.2666 254.905 57.6705 254.544 54.3844H262.874C263.137 55.436 263.762 56.2575 264.747 56.849C265.733 57.4734 266.949 57.7856 268.395 57.7856C270.137 57.7856 271.517 57.2762 272.536 56.2575C273.587 55.2716 274.113 53.6779 274.113 51.4761V47.582C273.292 48.8636 272.158 49.9152 270.712 50.7368C269.266 51.5254 267.574 51.9198 265.635 51.9198C263.367 51.9198 261.313 51.3447 259.473 50.1945C257.633 49.0115 256.171 47.352 255.086 45.216C254.035 43.0471 253.509 40.5497 253.509 37.7236C253.509 34.8975 254.035 32.4164 255.086 30.2804C256.171 28.1444 257.633 26.5014 259.473 25.3512C261.313 24.2011 263.367 23.626 265.635 23.626ZM274.113 37.7729C274.113 35.6697 273.522 34.0102 272.339 32.7943C271.188 31.5785 269.775 30.9705 268.099 30.9705C266.423 30.9705 264.994 31.5785 263.811 32.7943C262.661 33.9774 262.086 35.6204 262.086 37.7236C262.086 39.8267 262.661 41.5027 263.811 42.7514C264.994 43.9673 266.423 44.5752 268.099 44.5752C269.775 44.5752 271.188 43.9673 272.339 42.7514C273.522 41.5355 274.113 39.876 274.113 37.7729Z"
                className={logoTextClassName}
            />
            <path
                d="M238.729 23.7246C241.95 23.7246 244.513 24.7762 246.419 26.8793C248.358 28.9496 249.327 31.8085 249.327 35.4562V51.5255H240.948V36.5899C240.948 34.7497 240.471 33.3202 239.518 32.3015C238.565 31.2828 237.284 30.7734 235.673 30.7734C234.063 30.7734 232.782 31.2828 231.829 32.3015C230.876 33.3202 230.399 34.7497 230.399 36.5899V51.5255H221.97V24.0204H230.399V27.668C231.253 26.4521 232.404 25.4991 233.85 24.809C235.295 24.0861 236.922 23.7246 238.729 23.7246Z"
                className={logoTextClassName}
            />
            <path
                d="M211.671 21.1611C210.193 21.1611 208.977 20.7339 208.024 19.8795C207.104 18.9922 206.644 17.9078 206.644 16.6262C206.644 15.3117 207.104 14.2273 208.024 13.3729C208.977 12.4856 210.193 12.042 211.671 12.042C213.117 12.042 214.3 12.4856 215.221 13.3729C216.174 14.2273 216.65 15.3117 216.65 16.6262C216.65 17.9078 216.174 18.9922 215.221 19.8795C214.3 20.7339 213.117 21.1611 211.671 21.1611ZM215.861 24.02V51.5251H207.432V24.02H215.861Z"
                className={logoTextClassName}
            />
            <path
                d="M193.771 51.5251L185.391 39.9907V51.5251H176.962V15.0488H185.391V35.2094L193.721 24.02H204.122L192.686 37.8219L204.221 51.5251H193.771Z"
                className={logoTextClassName}
            />
            <path
                d="M164.307 28.6045C165.293 27.0929 166.525 25.9099 168.004 25.0555C169.483 24.1682 171.126 23.7246 172.933 23.7246V32.6465H170.616C168.513 32.6465 166.936 33.1066 165.884 34.0267C164.833 34.914 164.307 36.4913 164.307 38.7588V51.5255H155.878V24.0204H164.307V28.6045Z"
                className={logoTextClassName}
            />
            <path
                d="M137.013 51.9198C134.318 51.9198 131.887 51.3447 129.718 50.1945C127.582 49.0444 125.889 47.4013 124.641 45.2653C123.425 43.1293 122.817 40.6318 122.817 37.7729C122.817 34.9468 123.441 32.4657 124.69 30.3297C125.939 28.1609 127.647 26.5014 129.816 25.3512C131.985 24.2011 134.417 23.626 137.112 23.626C139.806 23.626 142.238 24.2011 144.407 25.3512C146.576 26.5014 148.285 28.1609 149.533 30.3297C150.782 32.4657 151.406 34.9468 151.406 37.7729C151.406 40.599 150.766 43.0964 149.484 45.2653C148.235 47.4013 146.51 49.0444 144.308 50.1945C142.139 51.3447 139.708 51.9198 137.013 51.9198ZM137.013 44.6245C138.623 44.6245 139.987 44.033 141.104 42.85C142.254 41.667 142.83 39.9746 142.83 37.7729C142.83 35.5711 142.271 33.8788 141.154 32.6958C140.069 31.5128 138.722 30.9212 137.112 30.9212C135.469 30.9212 134.105 31.5128 133.02 32.6958C131.936 33.8459 131.394 35.5383 131.394 37.7729C131.394 39.9746 131.919 41.667 132.971 42.85C134.055 44.033 135.403 44.6245 137.013 44.6245Z"
                className={logoTextClassName}
            />
            <path
                d="M120.265 16.9219L111.245 51.5251H101.041L95.5205 28.752L89.8026 51.5251H79.5991L70.8251 16.9219H79.8456L84.8241 42.1103L90.9856 16.9219H100.253L106.168 42.1103L111.195 16.9219H120.265Z"
                className={logoTextClassName}
            />
            <rect
                x="18.3151"
                y="-0.90625"
                width="50.8839"
                height="55.1243"
                rx={17}
                transform="rotate(22.5113 18.3151 -0.90625)"
                className={logoIconClassName}
            />
            <path
                d="M52.5416 19.5C53.244 19.5 53.7276 20.2052 53.4744 20.8604L42.6567 48.8604C42.5078 49.2458 42.1371 49.5 41.7239 49.5H32.2377C31.6956 49.5 31.2522 49.068 31.2381 48.5261L30.8192 32.4661L24.8794 48.841C24.7359 49.2366 24.3602 49.5 23.9394 49.5H14.4457C13.9127 49.5 13.4734 49.082 13.4469 48.5497L12.0523 20.5497C12.0238 19.9786 12.4792 19.5 13.051 19.5H20.6216C21.1856 19.5 21.638 19.9662 21.6211 20.5299L21.0865 38.3983L27.8712 20.1515C28.0169 19.7598 28.3907 19.5 28.8085 19.5H36.9222C37.4604 19.5 37.902 19.9259 37.9215 20.4637L38.5731 38.3983L44.1318 20.2078C44.2603 19.7872 44.6484 19.5 45.0881 19.5H52.5416Z"
                className="fill-white"
            />
        </svg>
    );
};

export default Logo;
