import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';
import {getBaseClassNames, getSizeClassNames, getVariantClassNames} from "../BeButton/utils";
import {Link} from "react-router-dom";



export type BeLinkProps = ComponentPropsWithoutRef<typeof Link> & {
    size?: 'sm' | 'md' | 'lg';
    variant?: 'filled' | 'outlined' | 'ghost';
    fullWidth?: boolean;
    iconOnly?: boolean;
};

const BeLink = ({
    children,
    size = 'md',
    variant = 'filled',
    fullWidth = false,
    iconOnly = false,
    className,
    ...rest
}: BeLinkProps) => {
    const classNames = twMerge(
        'p-button',
        getBaseClassNames(fullWidth),
        getSizeClassNames(size, iconOnly),
        getVariantClassNames(variant),
        className
    );

    return (
        <Link className={classNames} {...rest}>
            {children}
        </Link>
    );
};

export default BeLink;
