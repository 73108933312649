import { Property } from 'csstype';
import { Message, MessageProps } from 'primereact/message';
import { twMerge } from 'tailwind-merge';

export type BeMessageProps = MessageProps & {
    align?: Property.AlignItems;
    justify?: Property.JustifyContent;
    iconClassName?: string;
    contentClassName?: string;
};

const BeMessage = ({
    align = 'center',
    justify = 'flex-start',
    iconClassName = '',
    contentClassName = '',
    className,
    ...rest
}: BeMessageProps) => (
    <Message
        pt={{
            root: {
                className: twMerge('p-2.5', className),
                style: { alignItems: align, justifyContent: justify },
            },
            icon: { className: iconClassName },
            text: { className: twMerge('text-sm', contentClassName) },
        }}
        {...rest}
    />
);

export default BeMessage;
