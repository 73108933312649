import { Toast, ToastMessage } from 'primereact/toast';
import { RefObject, useCallback } from 'react';

const useToast = (toastRef: RefObject<Toast> | null) => {
    const showToast = useCallback(
        (toastMessage: ToastMessage | ToastMessage[]) =>
            toastRef?.current?.show(toastMessage),
        [toastRef]
    );
    const removeToast = useCallback(
        (toastMessage: ToastMessage | ToastMessage[]) =>
            toastRef?.current?.remove(toastMessage),
        [toastRef]
    );
    const replaceToast = useCallback(
        (toastMessage: ToastMessage | ToastMessage[]) =>
            toastRef?.current?.replace(toastMessage),
        [toastRef]
    );
    const clearAll = useCallback(() => toastRef?.current?.clear(), [toastRef]);

    return { showToast, removeToast, replaceToast, clearAll };
};

export default useToast;
