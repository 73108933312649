import { Tag, TagProps } from 'primereact/tag';
import { twMerge } from 'tailwind-merge';

import { getSizeClassNames, getVariantClassNames } from './utils';

export type BeBadgeProps = TagProps & {
    size?: 'sm' | 'md' | 'lg';
    variant?: 'filled' | 'outlined';
};

const BeBadge = ({
    size = 'sm',
    variant = 'filled',
    severity,
    rounded = true,
    className,
    ...rest
}: BeBadgeProps) => {
    const rootClassNames = twMerge(
        getSizeClassNames(size),
        getVariantClassNames(variant, severity),
        className
    );
    return (
        <Tag
            className={rootClassNames}
            pt={{
                value: {
                    className: /*tw*/ 'text-inherit',
                },
            }}
            rounded={rounded}
            {...rest}
        />
    );
};

export default BeBadge;
