import {AppDispatch} from "../store";
import {getAxios} from "../../utils";
import {PLACEMENTS_LOAD, PLACEMENTS_LOADED, PLACEMENTS_LOADED_ERROR} from "./placementActions";
import {WorkspaceRole} from "../../contexts/User/types";
import {IntegrationType} from "../../enums/integrationType";
import {MetaDataType} from "../../enums/metaDataType";

export function getPlacements(pageLink = '', selectedWorkspace: WorkspaceRole) {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: PLACEMENTS_LOAD });

        const axios = await getAxios();
        return axios.post(
            '/api/Jobs/GetPlacementList', {
                integrationType: IntegrationType.JobAdder,
                workspaceId: selectedWorkspace.workspaceId,
                ...(pageLink && {
                    requestMetadata: {
                        type: MetaDataType.PaginationLink,
                        data: pageLink
                    }
                })
            }
        )
            .then((r) => {
                dispatch({
                    type: PLACEMENTS_LOADED,
                    placements: r.data.results,
                    totalPlacements: r.data.recordCount,
                    pageLinks: {
                        first: r.data.firstLink,
                        previous: r.data.previousLink,
                        next: r.data.nextLink,
                        last: r.data.lastLink
                    }
                });
            })
            .catch((e) => dispatch({ type: PLACEMENTS_LOADED_ERROR, error: e }));
    };
}