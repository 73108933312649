import flattenDeep from 'lodash/flattenDeep';
import {Route, Routes as ReactRoutes} from 'react-router-dom';
import {RouteConfig} from "./interfaces/routeConfig";
import ProtectedRoutes from "./protectedRoutes";
import {UserRole} from "../core/enums/userRole";


const generateFlattenRoutes = (routes: RouteConfig[]): RouteConfig[] => {
    if (!routes) return [];
    return flattenDeep(
        routes.map(({ routes: subRoutes, ...rest }) => [
            rest,
            generateFlattenRoutes(subRoutes ?? [])
        ])
    );
}

export const renderRoutes = (mainRoutes: RouteConfig[]) => {
    return ({isAuthorized, role}: { isAuthorized: boolean; role: UserRole|undefined; }) => {
        const layouts = mainRoutes.map(({layout: Layout, routes}, index) => {

            if (routes && Layout) {
                const subRoutes = generateFlattenRoutes(routes);

                return (
                    <Route key={index} element={<Layout/>}>
                        { subRoutes
                            .filter((s) => s.isPublic || (role && s.userRole?.includes(role) || !s.userRole))
                            .map(({component: Component, path, name, isPublic}) => {
                                return (
                                    <Route
                                        key={name}
                                        element={
                                            <ProtectedRoutes
                                                isAuthorized={isAuthorized}
                                                isPublic={isPublic}
                                            />
                                        }
                                    >
                                        {Component && path && (
                                            <Route element={<Component/>} path={path}/>
                                        )}
                                    </Route>
                                );
                        })}
                    </Route>
                );
            }
            });
            return <ReactRoutes>{layouts}</ReactRoutes>;
    };
}