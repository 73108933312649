import { CircleNotch } from '@phosphor-icons/react';
import { ErrorMessage, Form, Formik } from 'formik';
import {useContext, useEffect, useState} from 'react';
import validationSchema from './validationSchema';
import {BeButton} from "../../../core/beUi";
import { useNavigate} from "react-router-dom";
import {Dropdown} from "primereact/dropdown";
import {UserContext} from "../../../core/contexts";


export default function SelectWorkspacePage() {
    const navigate = useNavigate();
    const { userAttributes, selectWorkspace } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [workspaces, setWorkSpaces] = useState<any>([]);

    const handleSelectWorkspace = async (workspaceId: string) => {
        setLoading(true);
        if(selectWorkspace) {
            selectWorkspace(Number(workspaceId));
            setTimeout(() => navigate('/dashboard'), 500);
        }
        setLoading(false);
    };

    useEffect(() => {
        if(userAttributes?.roles) {
            const ws = userAttributes.roles.map(w => ({label: w.workspaceName, value: w.workspaceId}));
            setWorkSpaces(ws);
        }
    }, [userAttributes]);

        return (<Formik
                initialValues={{workspaceId: ''}}
                validationSchema={validationSchema}
                onSubmit={({workspaceId}) => handleSelectWorkspace(workspaceId)}
            >
                {({values, handleChange}) => (
                    <Form className="w-full">
                        <div className="flex flex-col items-center gap-y-6">
                            <div className="w-full max-w-lg rounded-sm bg-white">
                                <h3 className="border-b border-brandGray-5 p-6 pb-5 text-center text-2xl font-bold sm:p-8 sm:pb-6">
                                    Select an agency
                                </h3>
                                <div
                                    className="flex flex-col items-stretch justify-start gap-y-4 px-6 py-5 sm:px-8 sm:py-6">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-sm">
                                            Agency
                                        </label>
                                        <Dropdown
                                            name="workspaceId"
                                            value={values.workspaceId}
                                            options={workspaces}
                                            optionLabel="label"
                                            placeholder="Select an agency"
                                            onChange={handleChange}
                                            pt={{
                                                root: {
                                                    className:
                                                        'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none',
                                                },
                                                input: {
                                                    className:
                                                        'py-1.5 px-2 text-sm text-secondary',
                                                },
                                                trigger: {
                                                    className:
                                                        'text-secondary-light w-auto py-1.5 px-2',
                                                },
                                                item: {
                                                    className:
                                                        'text-secondary aria-selected:bg-primary-light p-2',
                                                },
                                                list: {
                                                    className: 'py-2',
                                                },
                                            }}
                                        />
                                        <ErrorMessage name="role"/>
                                    </div>
                                </div>
                                <div
                                    className="flex flex-col justify-stretch gap-x-6 gap-y-4 border-t border-brandGray-5 p-6 pt-5 sm:flex-row sm:p-8 sm:pt-6">
                                    <BeButton
                                        type="submit"
                                        size="lg"
                                        fullWidth
                                        disabled={!values.workspaceId}
                                    >
                                        {loading ? (
                                            <CircleNotch
                                                size="1.75rem"
                                                className="animate-spin"
                                            />
                                        ) : (
                                            'Continue'
                                        )}
                                    </BeButton>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
        </Formik>);
}
