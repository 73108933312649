export const getSizeClassNames = (size: string) => {
    switch (size) {
        case 'sm':
            return /*tw*/ 'py-4 px-2 text-lg font-normal w-10';
        case 'md':
            return /*tw*/ 'py-6 px-3 text-xl font-medium w-12';
        case 'lg':
            return /*tw*/ 'py-8 px-4 text-2xl font-semibold w-14';
        default:
            return /*tw*/ 'py-6 px-3 text-xl font-medium w-12';
    }
};

export const getThemeClassNames = () =>
    /*tw*/ 'text-secondary placeholder:text-brandGray-2 hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40';
