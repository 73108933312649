import { Toast } from 'primereact/toast';
import { ReactNode, useRef } from 'react';

import ToastContext from './ToastContext';

const ToastContextProvider = ({
    children,
}: Readonly<{
    children: ReactNode;
}>) => {
    const toastRef = useRef<Toast>(null);
    return (
        <ToastContext.Provider value={toastRef}>
            {children}
        </ToastContext.Provider>
    );
};

export default ToastContextProvider;
