import { object, string } from 'yup';
import {BeMessage} from "../../../core/beUi";


const astuteValidationSchema = object().shape({
    username: string().required(() => (
        <BeMessage severity="error" text="Username is required" />
    )),
    password: string().required(() => (
        <BeMessage severity="error" text="Password is required" />
    )),
});

export default astuteValidationSchema;
