import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';

const UsersLoading = () => {
    const items = Array(10).fill(undefined);

    return (
        <div className="flex flex-col gap-y-6">
            <h2 className="mb-4 text-4xl font-bold text-black">Users</h2>
            <div className="flex items-center justify-between gap-x-6">
                <Skeleton height="2.5rem" width="20rem" />
                <Skeleton height="2.5rem" width="7rem" />
            </div>
            <div className="overflow-x-auto scrollbar-thin">
                <div className="inline-block min-w-full align-middle">
                    <DataTable
                        value={items}
                        rows={10}
                        tableClassName="min-w-full max-w-max"
                    >
                        <Column
                            field="name"
                            header="Name"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="jobAdderId"
                            header="JobAdderCallback ID"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="role"
                            header="Role"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="status"
                            header="Status"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="lastActive"
                            header="Last Active"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            header="Action"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default UsersLoading;
