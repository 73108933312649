import { Toast, ToastProps } from 'primereact/toast';
import { forwardRef, Ref } from 'react';

export type BeToastProps = Omit<ToastProps, 'children'>;

const BeToast = forwardRef(function BeToast(
    props: BeToastProps,
    ref: Ref<Toast>
) {
    return (
        <Toast
            ref={ref}
            pt={{
                content: {
                    className: /*tw*/ 'p-2 border-none justify-between',
                },
                icon: { className: /*tw*/ 'w-6 h-6' },
                text: { className: /*tw*/ 'ml-2' },
                detail: { className: /*tw*/ 'mt-1 text-sm' },
                summary: { className: /*tw*/ 'capitalize' },
            }}
            {...props}
        />
    );
});

export default BeToast;
