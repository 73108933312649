
import {ConnectedAppsContext, UserContext} from "../../core/contexts";
import {Skeleton} from "primereact/skeleton";
import {routes} from "../../routes/routes";
import {BeButton, BeInputTextarea} from "../../core/beUi";
import {Question} from "@phosphor-icons/react";
import {OverlayPanel} from "primereact/overlaypanel";
import {ErrorMessage, Form, Formik} from "formik";
import validationSchema from "../utils/validationSchema";
import {useLocation, useNavigate} from "react-router-dom";
import {useWindowSize} from "usehooks-ts";
import {RouteConfig} from "../../routes/interfaces/routeConfig";
import {useContext, useEffect, useRef, useState} from "react";

const Sidebar = () => {
    const { userAttributes, selectedWorkspace } = useContext(UserContext);
    const { isAllConnected } = useContext(ConnectedAppsContext);
    const [isLoading, setIsLoading] = useState(true);
    const [navLinks, setNavLinks] = useState<RouteConfig[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const overlayPanelRef = useRef<OverlayPanel>(null);
    const { width } = useWindowSize();

    const pathMatchCheck = (path: string, pathname: string): boolean => {
      return (path === pathname || pathname.startsWith(`${path}/`));
    }

    useEffect(() => {
        setIsLoading(!userAttributes?.roles || isAllConnected === null);
    }, [userAttributes, isAllConnected]);

    useEffect(() => {
        const links = routes
            .filter(route => route.name === 'main' && route.routes)
            .flatMap(route => route.routes || [])
            .filter(subRoute => subRoute?.sidebarLink === true && (!subRoute.userRole || subRoute.userRole.includes(selectedWorkspace?.role || 0)));

        setNavLinks(links);
    }, [userAttributes]);


    return (
        <nav className="h-full w-auto shrink-0 grow-0 bg-secondary p-4 lg:w-56 lg:p-5">
            <ul className="flex h-full flex-col items-stretch justify-start gap-y-3">
                {isLoading ? (
                    <>
                    { navLinks.map((r, i) => <li key={i}>
                            <Skeleton
                                height="3rem"
                                className="bg-secondary-light"
                            />
                        </li>) }

                    </>
                ) : (
                    navLinks.map(
                        ({ name, title, path, icon: Icon, disableIfNotAllConnected }) => (
                            <li key={name}>
                                <BeButton
                                    disabled={disableIfNotAllConnected && !isAllConnected}
                                    className={`${ path && pathMatchCheck(path, pathname) ? 'bg-primary font-medium text-secondary hover:bg-primary' : 'bg-transparent font-normal text-white hover:bg-secondary-light'} flex items-center justify-start gap-x-2.5 border-none p-3 text-sm`}
                                    onClick={() => path && navigate(path)}
                                    fullWidth
                                >
                                    { Icon && <Icon size="1.75rem" /> }
                                    {width >= 1024 && title}
                                </BeButton>
                            </li>
                        )
                    )
                )}
                <li className="mt-auto">
                    <BeButton
                        className="flex w-full items-center justify-start gap-x-2.5 border-none bg-transparent p-3 text-sm font-normal text-white hover:bg-secondary-light"
                        onClick={overlayPanelRef.current?.toggle}
                    >
                        <Question size="1.75rem" />
                        {width >= 1024 && 'Feedback'}
                    </BeButton>
                    <OverlayPanel ref={overlayPanelRef}>
                        <Formik
                            initialValues={{ message: '' }}
                            validationSchema={validationSchema}
                            onSubmit={() =>
                                overlayPanelRef.current?.hide()
                            }
                        >
                            {({ values, handleChange }) => (
                                <Form>
                                    <div className="flex flex-col items-center gap-y-6">
                                        <div className="flex flex-col gap-1">
                                            <label className="text-sm">
                                                Message
                                            </label>
                                            <BeInputTextarea
                                                className="w-full"
                                                placeholder="Write your message..."
                                                name="message"
                                                values={values}
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage name="message" />
                                        </div>
                                        <BeButton
                                            type="submit"
                                            fullWidth
                                        >
                                            Submit
                                        </BeButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </OverlayPanel>
                </li>
            </ul>
        </nav>);

}
export default Sidebar;