import {
    ArrowBendRightUp,
    CalendarBlank, CurrencyCircleDollar, CurrencyDollarSimple,
    Invoice,
    Percent,
    Scroll,
    Target,
    TrendUp,
    UserFocus, WaveTriangle
} from '@phosphor-icons/react';
import { Avatar } from 'primereact/avatar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import  { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart,
    ChartData,
    ChartOptions,
    DefaultDataPoint,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';



import DashboardLoading from './dashboardLoading';
import { tableRows } from './mockData';
import {withConnectedApps} from "../../core/hoc";
import {BeBadge, BeButton} from "../../core/beUi";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../core/hooks";
import FrequencyPicker from "./filters/frequencyPicker";
import CardWidget from "./widgets/CardWidget";

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

Tooltip.positioners.offsetPosition = (activeItems) => {
    const isTop = activeItems[0]?.element.y > 120;
    return {
        x: activeItems[0]?.element.x,
        y: isTop
            ? activeItems[0]?.element.y - 20
            : activeItems[0]?.element.y + 20,
        yAlign: isTop ? 'bottom' : 'top',
    };
};

const cards = [
    { id: 1, label: 'Your threshold', value: '$91,162.50', icon: WaveTriangle },
    { id: 2, label: 'Your bonus', value: '45%', icon: Percent },
    { id: 3, label: 'Your billings', value: '$10,355.53', icon: Invoice },
    { id: 4, label: 'Bonus amount', value: '$6,836.86', icon: CurrencyDollarSimple },
];

const DashboardPage = () => {

    const computedStyles = getComputedStyle(document.documentElement);
    const twBlue = computedStyles.getPropertyValue('--bluegray-900');
    const interFont = computedStyles.getPropertyValue('--font-inter');
    const navigate = useNavigate();
    const { userAttributes } = useAuth();

    const [chartData] = useState<
        ChartData<'line', DefaultDataPoint<'line'>, string>
    >({
        labels: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
        ],
        datasets: [
            {
                label: 'Billings',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: twBlue,
                tension: 0.5,
                pointStyle: 'circle',
                pointBackgroundColor: 'transparent',
                pointBorderWidth: 10,
                pointBorderColor: 'transparent',
                pointRadius: 10,
                pointHoverBackgroundColor: twBlue,
                pointHoverBorderColor: '#FFF',
                pointHoverBorderWidth: 10,
                pointHoverRadius: 10,
            },
        ],
    });
    const [chartOptions] = useState<ChartOptions<'line'>>({
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        interaction: {
            intersect: false,
            axis: 'xy',
            mode: 'nearest',
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                position: 'offsetPosition',
                backgroundColor: '#FFF',
                bodyAlign: 'center',
                displayColors: false,
                cornerRadius: 8,
                bodyColor: '#0A112F',
                padding: 12,
                titleFont: {
                    family: interFont,
                    size: 14,
                    weight: 'bold',
                },
                titleMarginBottom: 10,
                titleAlign: 'center',
                titleColor: '#70707A',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                display: false,
            },
        },
    });




    return (
        <div className="flex flex-col gap-y-6">
            <div className="flex items-center justify-between gap-x-6">
                <div>
                    <div className="flex flex-row gap-x-2">
                        <h2 className="text-4xl font-bold text-black">Welcome,</h2>
                        <h2 className="text-4xl font-medium text-black">{userAttributes?.given_name} {userAttributes?.family_name}</h2>
                    </div>
                    <h6 className="font-normal text-gray-600">Your dashboard to track and review recruitment progress</h6>
                </div>
                <div>
                    <FrequencyPicker />
                </div>
            </div>
            <div className="flex flex-wrap justify-stretch gap-6">
                {cards.map((card) => (
                    <div key={card.id} className="flex flex-1 basis-1/5 flex-col items-start justify-start gap-y-6">
                        <CardWidget label={card.label} value={card.value} icon={card.icon}  />
                    </div>
                ))}
            </div>
            <div className="rounded-2xl border border-solid bg-gradient-to-t from-primary from-2% via-primaryLight via-6% to-white to-85% p-4">
                <div className="flex flex-col gap-y-2">
                    <div className="flex items-center justify-between gap-x-6">
                        <div className="flex flex-row items-center gap-x-2">
                            <Avatar

                                shape="circle"
                                className="bg-primary group-hover:bg-primary-light"
                            >
                                <Invoice size="1.4rem"/>
                            </Avatar>
                            <span>Your billings</span>
                        </div>
                        <div className="flex gap-x-2">
                            <BeBadge
                                value="+23%"
                                severity="success"
                                className="text-base font-medium"
                            />
                            <span className="font-light">vs previous quarter</span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-x-6">
                        <span className="text-4xl font-medium text-secondary">
                            {cards[2].value}
                        </span>

                    </div>
                </div>
                <div className="min-h-48">
                    <Line data={chartData} options={chartOptions} />
                </div>
            </div>
            <div className="rounded-2xl bg-white p-6 shadow-sm">
                <div className="mb-5 flex items-center justify-between gap-x-6">
                    <div className="flex flex-row gap-x-2">
                        <div>
                        <h2 className="text-2xl font-bold text-secondary">Latest placements</h2>
                        <h6 className="font-normal text-gray-600">10 new placements in the last 24 hours</h6>
                        </div>
                    </div>
                    <BeButton
                        variant="outlined"
                        size="sm"
                        className="rounded-full"
                        onClick={() => navigate('/placements')}
                    >
                        View all
                    </BeButton>
                </div>
                <div className="overflow-x-auto scrollbar-thin">
                    <div className="inline-block min-w-full align-middle">
                        <DataTable
                            value={tableRows}
                            paginator
                            rows={5}
                            tableClassName="min-w-full max-w-max"
                        >
                            <Column
                                field="candidateName"
                                header="Candidate Name"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="jobTitle"
                                header="Job Title"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="company"
                                header="Company"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="type"
                                header="Type"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="recruiter"
                                header="Recruiter"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="bonus"
                                header="Bonus"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="split"
                                header="Split"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="startDate"
                                header="Start Date"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="invoiceId"
                                header="INV-#"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="invoiceDue"
                                header="Invoice Due"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="invoiceStatus"
                                header="Invoice Status"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withConnectedApps(DashboardPage, DashboardLoading);
