import { object, string } from 'yup';
import {BeMessage} from "../../core/beUi";


const validationSchema = object().shape({
    message: string().required(() => (
        <BeMessage severity="error" text="Message is required" />
    )),
});

export default validationSchema;