import {useContext} from 'react';
import {Routes} from "./routes/routes";
import {UserContext} from "./core/contexts";
import SplashLoader from "./layouts/common/splashLoader/fullscreenLoader";

function App() {
  const { currentUser, isLoading, selectedWorkspace } = useContext(UserContext);
  return (isLoading || currentUser === null) ? (<SplashLoader />) :  ( <Routes isAuthorized={!!currentUser} role={selectedWorkspace?.role} />);

}

export default App;
