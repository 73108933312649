import {ProgressSpinner} from "primereact/progressspinner";

import Logo from "../logo/logo";
import Footer from "../footer/footer";
const SplashLoader = () => {
    return (
        <div className="flex flex-1 flex-col items-stretch bg-primary">
            <div className="flex flex-1 flex-col items-center">
                <div className="max-w-80 p-8">
                    <Logo />
                </div>
                <div className="flex w-full flex-1 flex-col items-center justify-center">
                    <ProgressSpinner
                        strokeWidth="3"
                        className="size-40"
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SplashLoader;
