import { object, string } from 'yup';

import { BeMessage } from '../../../../core/beUi';

const validationSchema = object().shape({
    workspaceName: string().required(() => (
        <BeMessage severity="error" text="Workspace name is required" />
    )),
});

export default validationSchema;
