import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, SetupIntent } from '@stripe/stripe-js';
import { useContext, useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';
import { getStripe } from '../../core/utils';
import { useApi } from '../../core/hooks';
import { UserContext } from '../../core/contexts';
import BillingLoading from './billingLoading';
import BillingForm from "./billingForm";

const BillingPage = () => {
    const { userAttributes } = useContext(UserContext);
    const reqConfig = useMemo<AxiosRequestConfig>(
        () => ({
            method: 'GET',
            baseURL: process.env.REACT_APP_API,
            url: 'api/Billing/CreateSetupIntent',
            params: {
                stripeId: userAttributes?.stripeId,
            },
        }),
        [userAttributes]
    );
    const { data, loading, error } = useApi<SetupIntent>(reqConfig);
    const stripe = useMemo(() => getStripe(), []);
    const results = (data as any)?.results?.data[0];

    if (loading) return <BillingLoading />;
    if (!results?.client_secret || error) return null;

    const stripeElementOptions: StripeElementsOptions = {
        clientSecret: results.client_secret,
        loader: 'always',
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#212529',
                colorText: '#212529',
            },
        },
    };

    return (
        <>
        <Elements options={stripeElementOptions} stripe={stripe}>
            <BillingForm />
        </Elements>
        </>
    );
};

export default BillingPage;
