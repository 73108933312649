import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';

const PlacementsLoading = () => {
    const items = Array(10).fill(undefined);

    return (
        <div className="flex flex-col gap-y-6">
            <h2 className="mb-4 text-4xl font-bold text-black">Placements</h2>
            <div className="flex items-center justify-between gap-x-6">
                <Skeleton height="2.5rem" width="20rem" />
                <Skeleton height="2.5rem" width="7rem" />
            </div>
            <div className="overflow-x-auto scrollbar-thin">
                <div className="inline-block min-w-full align-middle">
                    <DataTable
                        value={items}
                        rows={10}
                        tableClassName="min-w-full max-w-max"
                    >
                        <Column
                            field="candidateName"
                            header="Candidate Name"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="jobTitle"
                            header="Job Title"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="company"
                            header="Company"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="type"
                            header="Type"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="bonus"
                            header="Split"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="split"
                            header="Billing"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="split"
                            header="Margin"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="startDate"
                            header="Start Date"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="invoiceId"
                            header="INV-#"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                        <Column
                            field="invoiceStatus"
                            header="Invoice Status"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            body={<Skeleton height="2rem" />}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default PlacementsLoading;
