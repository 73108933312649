import { faker } from '@faker-js/faker';

type TableRow = {
    candidateName: string;
    jobTitle: string;
    company: string;
    type: string;
    recruiter: string;
    bonus: string;
    split: string;
    startDate: string;
    invoiceId: string;
    invoiceDue: string;
    invoiceStatus: string;
};

function createRandomTableRow(): TableRow {
    return {
        candidateName: faker.person.fullName(),
        jobTitle: faker.person.jobTitle(),
        company: faker.company.name(),
        type: faker.helpers.arrayElement(['Contract', 'Permanent']),
        recruiter: faker.company.name(),
        bonus: `$${faker.number.int({ min: 500, max: 3000 })}`,
        split: `${faker.number.int({ min: 1, max: 100 })}%`,
        startDate: faker.date.past().toDateString(),
        invoiceId: `INV-${faker.number.int({ min: 3500, max: 9000 })}`,
        invoiceDue: faker.date.future().toDateString(),
        invoiceStatus: faker.helpers.arrayElement(['Paid', 'Not send']),
    };
}

export const tableRows: TableRow[] = faker.helpers.multiple(
    createRandomTableRow,
    {
        count: 50,
    }
);
