

import { CircleNotch } from '@phosphor-icons/react';
import { confirmSignIn, signIn } from 'aws-amplify/auth';
import { ErrorMessage, Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';


import { useToast } from '../../../core/hooks';
import {ToastContext, UserContext} from '../../../core/contexts';

import validationSchema from './validateSchema';
import {BeButton, BeInputText, BeLink} from "../../../core/beUi";
import {Link, Navigate, useNavigate} from "react-router-dom";
import OtpCode from "./otpCode";

export default function SignInPage() {
    const toastRef = useContext(ToastContext);
    const navigate = useNavigate();
    const { showToast } = useToast(toastRef);
    const { currentUser, isLoading } = useContext(UserContext);
    const [verifyCode, setVerifyCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recipient, setRecipient] = useState('');
    const [otpComponentKey, setOtpComponentKey] = useState(uuid());



    if(!!currentUser){
        navigate('/dashboard');
    }

    const handleSignIn = async (username: string) => {
        try {
            setLoading(true);
            const { isSignedIn, nextStep } = await signIn({
                username,
                options: {
                    authFlowType: 'CUSTOM_WITHOUT_SRP',
                },
            });
            setRecipient((nextStep as any).additionalInfo.email);
            if (
                !verifyCode &&
                !isSignedIn &&
                nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE'
            )
                setVerifyCode(true);
        } catch (error) {
            if (error instanceof Error) {
                showToast({
                    severity: 'error',
                    summary: 'failed',
                    detail:
                        error.name === 'UserNotFoundException'
                            ? "Sorry, we couldn't find an account with that email."
                            : error.message,
                });
            }
        }
        setLoading(false);
        verifyCode && setOtpComponentKey(uuid());
    };
    const verifyOtpCode = async (otp: string) => {
        try {
            const { isSignedIn } = await confirmSignIn({
                challengeResponse: otp,
            });
            if (isSignedIn) {
                showToast({
                    severity: 'success',
                    summary: 'success',
                    detail: 'You have signed in successfully!',
                });
            } else throw new Error();
        } catch (err) {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: 'Sorry, the code you entered is incorrect or expired',
            });
        }
    };

    if(currentUser){
        return (<Navigate to="/dashboard" />);
    } else {

        return verifyCode && recipient ? (
            <OtpCode
                key={otpComponentKey}
                recipient={recipient}
                recipientType={'email'}
                resendCode={() => handleSignIn(recipient)}
                isResendDisabled={loading}
                handleNext={verifyOtpCode}
                handleBack={() => {
                    setLoading(false);
                    setVerifyCode(false);
                }}
            />
        ) : (
            <Formik
                initialValues={{username: ''}}
                validationSchema={validationSchema}
                onSubmit={({username}) => handleSignIn(username)}
            >
                {({values, handleChange}) => (
                    <Form className="w-full">
                        <div className="flex flex-col items-center gap-y-6">
                            <div className="w-full max-w-lg rounded-sm bg-white">
                                <h3 className="border-b border-brandGray-5 p-6 pb-5 text-center text-2xl font-bold sm:p-8 sm:pb-6">
                                    Sign In
                                </h3>
                                <div
                                    className="flex flex-col items-stretch justify-start gap-y-4 px-6 py-5 sm:px-8 sm:py-6">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-sm">
                                            Email address
                                        </label>
                                        <BeInputText
                                            className="w-full"
                                            type="email"
                                            placeholder="Enter email address"
                                            name="username"
                                            values={values}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage name="username"/>
                                    </div>
                                </div>
                                <div
                                    className="flex flex-col justify-stretch gap-x-6 gap-y-4 border-t border-brandGray-5 p-6 pt-5 sm:flex-row sm:p-8 sm:pt-6">
                                    <BeLink
                                        to="/"
                                        size="lg"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        Back
                                    </BeLink>
                                    <BeButton
                                        type="submit"
                                        size="lg"
                                        fullWidth
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <CircleNotch
                                                size="1.75rem"
                                                className="animate-spin"
                                            />
                                        ) : (
                                            'Continue'
                                        )}
                                    </BeButton>
                                </div>
                            </div>
                            <p>
                                Need an account?{' '}
                                <Link
                                    to="/sign-up"
                                    className="underline underline-offset-4"
                                >
                                    Sign Up
                                </Link>
                            </p>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}
