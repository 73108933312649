import {
    InputNumber,
    InputNumberProps,
    InputNumberChangeEvent,
} from 'primereact/inputnumber';
import { forwardRef, Ref } from 'react';
import { twMerge } from 'tailwind-merge';

import { FormikProps } from '../common';

import { getSizeClassNames, getThemeClassNames } from './utils';

export type BeInputNumberProps = Omit<
    InputNumberProps,
    'children' | 'size' | 'type'
> &
    FormikProps & {
        type?: 'text' | 'number';
        size?: 'sm' | 'md' | 'lg';
    };

const BeInputNumber = forwardRef(function BeInputNumber(
    {
        type = 'text',
        size = 'md',
        pt = {},
        name,
        value,
        values = {},
        setFieldValue,
        onChange,
        className,
        ...rest
    }: BeInputNumberProps,
    ref: Ref<HTMLInputElement>
) {
    const inputValue: number | null | undefined =
        value || (name && values[name]);
    const inputOnChange = (e: InputNumberChangeEvent) => {
        onChange?.(e);
        name && setFieldValue?.(name, e.value);
    };
    const classNames = twMerge(
        getSizeClassNames(size),
        getThemeClassNames(),
        className
    );

    return (
        <InputNumber
            inputRef={ref}
            type={type}
            name={name}
            value={inputValue}
            onChange={inputOnChange}
            pt={{
                input: { root: { className: classNames } },
                ...pt,
            }}
            {...rest}
        />
    );
});

export default BeInputNumber;
