import { Dropdown } from 'primereact/dropdown';
import {
    ArrowLeft,
    Trash,
    CircleNotch,
    AsteriskSimple,
} from '@phosphor-icons/react';

import { ErrorMessage, Form, Formik } from 'formik';
import {useState, useContext, useEffect} from 'react';

import {useAuth, useToast} from '../../../core/hooks';
import { BeButton, BeInputNumber, BeInputText } from '../../../core/beUi';
import { ToastContext } from '../../../core/contexts';
import {getAxios, getErrorMessage} from '../../../core/utils';

import validationSchema from './validationSchema';
import {Image} from "primereact/image";
import {useNavigate, useParams} from "react-router-dom";
import {UserRole} from "../../../core/enums/userRole";
import {getUser} from "../../../core/state/users/usersEffects";
import {AppDispatch, RootState} from "../../../core/state/store";
import {useDispatch, useSelector} from "react-redux";
import {USERS_UNSELECT_USER} from "../../../core/state/users/usersActions";
import UserEditLoading from "./editUserLoading";

import {PhoneNumberFormat as PNF} from "google-libphonenumber";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const roles = [
    { label: 'National Manager', value: UserRole.NATIONAL_MANAGER },
    { label: 'Team Manager', value: UserRole.TEAM_MANAGER },
    { label: 'Resourcer', value: UserRole.RESOURCER },
    { label: 'Recruiter', value: UserRole.RECRUITER },
];

const countries = [
    { label: 'Australia', value: 'AU', icon: '/icons/australia.svg' },
    { label: 'New Zealand', value: 'NZ', icon: '/icons/new-zealand.svg' },
];


const selectedCountryCodeTemplate = (option: any, props: any) => {
    if (option) {
        return (
            <div className="flex items-center justify-start gap-2">
                <Image
                    alt={option.value}
                    src={option.icon}
                    width="16"
                    height="16"
                />
                <div>{option.label}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

const countryCodeOptionTemplate = (option: any) => {
    return (
        <div className="flex items-center justify-start gap-2">
            <Image
                alt={option.value}
                src={option.icon}
                width="24"
                height="24"
            />
            <div>{option.label}</div>
        </div>
    );
};


const UserEditPage = () => {

    const user = useSelector((state: RootState) => state.users?.selectedUser);
    const isLoading = useSelector((state: RootState) => state.users?.isLoadingSelectedUser);
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch: AppDispatch = useDispatch();
    const {selectedWorkspace} = useAuth();

    const handleUpdate = async (values: any) => {
        console.log(values);
        setSaving(true);
        try {

            const number = phoneUtil.parseAndKeepRawInput(values.phoneNumber, values.country);
            const axios = await getAxios();
            await axios.put('/api/User/UpdateWorkspaceUser', {
                userId: user?.id,
                firstName: values.firstName,
                lastName: values.lastName,
                country: values.country,
                phoneNumber: phoneUtil.format(number, PNF.E164),
                role: values.role,
                threshold: values.threshold,
                bonus: values.bonus,
                workspaceId: selectedWorkspace?.workspaceId
            });

            showToast({
                severity: 'success',
                summary: 'success',
                detail: 'User updated successfully!',
            });
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
        }
        setSaving(false);
    };

    const handleDeletion = async () => {
        if(user?.email) {
            setSaving(true);
            try {
                // await new Promise((resolve) => setTimeout(resolve, 1000));
                // const { data } = await axios.post('Prod/api/Workspace/RemoveWorkspaceUser', {
                // })
                const axios = await getAxios();
                await axios.post('/api/Workspace/RemoveWorkspaceUser', {
                    userId: user.id,
                    workspaceId: selectedWorkspace?.workspaceId
                });
                showToast({
                    severity: 'success',
                    summary: 'success',
                    detail: 'User removed successfully!',
                });
                navigate('/users');
            } catch (error) {
                console.log(error);
                if (error instanceof Error) {
                    showToast({
                        severity: 'error',
                        summary: 'failed',
                        detail:
                            error instanceof Error
                                ? error.message
                                : 'Something went wrong. Please, try again later.',
                    });
                }
            }
            setSaving(false);
        }
    };

    const goBack = () => {
        dispatch({type: USERS_UNSELECT_USER});
        navigate('/users');
    }

    useEffect(() => {
        if(id && selectedWorkspace) {
            dispatch(getUser(Number(id), selectedWorkspace));
        }
    }, [id, selectedWorkspace]);

    return (
        <>
            { isLoading &&  <UserEditLoading /> }
            { !isLoading && <>
                <ConfirmDialog />
                <div className="flex flex-col">
                <BeButton
                    onClick={()=> goBack()}
                    size="sm"
                    variant="ghost"
                    className="mb-6 self-start text-blue-500"
                >
                    <ArrowLeft size="1.75rem" />
                    Back
                </BeButton>
                <div className="mb-4 flex items-center justify-between gap-6">
                    <h2 className="text-4xl font-bold text-black">{user?.firstName} {user?.lastName}</h2>
                    <BeButton
                        size="sm"
                        icon={<Trash size="1.75rem" />}
                        variant="outlined"
                        className="border-red-500 bg-red-500 text-white hover:border-red-600 hover:bg-red-600 focus:ring-2 focus:ring-red-300"
                        disabled={saving}
                        onClick={() =>  confirmDialog({
                            message: <div className="-ml-4">Are you sure you want to remove this user?</div>,
                            header: 'Confirmation',
                            defaultFocus: 'none',
                            acceptLabel: 'Yes, proceed',
                            acceptClassName: 'text-white bg-red-600 border-none focus:ring-transparent',
                            rejectClassName: 'text-red-600 bg-white border-none focus:ring-transparent',
                            accept: () => handleDeletion()
                        })}
                    >
                        Remove
                    </BeButton>
                </div>
                <div className="flex flex-col gap-7 lg:flex-row lg:items-start">
                    <div className="grow flex-col items-start gap-6 rounded-xl bg-white p-8 shadow-sm">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                firstName: user?.firstName,
                                lastName: user?.lastName,
                                email: user?.email,
                                phoneNumber: user ? (phoneUtil.format(phoneUtil.parseAndKeepRawInput(user?.phoneNumber, user?.country), PNF.NATIONAL)).replaceAll(" ","") : '',
                                country: user?.country,
                                role: user?.role,
                                threshold: user?.bonusDetails?.threshold,
                                bonus: user?.bonusDetails?.bonus,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdate}
                        >
                            {({ values, setFieldValue, handleChange }) => (
                                <Form autoComplete="off">
                                    <div className="flex flex-col gap-y-8">
                                        <div className="flex flex-col gap-5">
                                            <h3 className="text-2xl font-bold">
                                                User Details
                                            </h3>
                                            <div className="flex flex-col gap-1">
                                                <div className="flex flex-col justify-stretch gap-4 sm:flex-row">
                                                    <div className="flex grow flex-col gap-1">
                                                        <label className="text-sm">
                                                            First Name
                                                        </label>
                                                        <BeInputText
                                                            className="w-full"
                                                            placeholder="Enter first name"
                                                            name="firstName"
                                                            values={values}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="flex grow flex-col gap-1">
                                                        <label className="text-sm">
                                                            Last Name
                                                        </label>
                                                        <BeInputText
                                                            className="w-full"
                                                            placeholder="Enter last name"
                                                            name="lastName"
                                                            values={values}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <ErrorMessage name="firstName"/>
                                                <ErrorMessage name="lastName"/>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <label className="text-sm">
                                                    Email address
                                                </label>
                                                <BeInputText
                                                    className="w-full"
                                                    placeholder="Enter email address"
                                                    name="email"
                                                    type="email"
                                                    values={values}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                <ErrorMessage name="email"/>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <label className="text-sm">
                                                    Country
                                                </label>
                                                <Dropdown
                                                    name="country"
                                                    value={values.country}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'country',
                                                            e.value
                                                        )
                                                    }
                                                    options={countries}
                                                    itemTemplate={
                                                        countryCodeOptionTemplate
                                                    }
                                                    valueTemplate={
                                                        selectedCountryCodeTemplate
                                                    }
                                                    optionLabel="label"
                                                    placeholder="Choose Country"
                                                    pt={{
                                                        root: {
                                                            className:
                                                                'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none',
                                                        },
                                                        input: {
                                                            className:
                                                                'py-1.5 px-2 text-sm text-secondary',
                                                        },
                                                        trigger: {
                                                            className:
                                                                'text-secondary-light w-auto py-1.5 px-2',
                                                        },
                                                        item: {
                                                            className:
                                                                'text-secondary aria-selected:bg-primary-light p-2',
                                                        },
                                                        list: {
                                                            className: 'py-2',
                                                        },
                                                    }}
                                                />
                                                <ErrorMessage name="country"/>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <div className="flex flex-col gap-4 sm:flex-row">
                                                    <div className="flex grow flex-col gap-1">
                                                        <label className="text-sm">
                                                            Mobile phone number
                                                        </label>
                                                        <BeInputText
                                                            className="w-full"
                                                            placeholder="Enter phone number"
                                                            name="phoneNumber"
                                                            values={values}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <ErrorMessage name="code"/>
                                                <ErrorMessage name="phoneNumber"/>
                                            </div>

                                            <div className="flex flex-col gap-1">
                                                <label className="text-sm">
                                                    Role
                                                </label>
                                                <Dropdown
                                                    name="role"
                                                    value={values.role}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'role',
                                                            e.value
                                                        )
                                                    }
                                                    options={roles}
                                                    optionLabel="label"
                                                    placeholder="User role"
                                                    pt={{
                                                        root: {
                                                            className:
                                                                'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none',
                                                        },
                                                        input: {
                                                            className:
                                                                'py-1.5 px-2 text-sm text-secondary',
                                                        },
                                                        trigger: {
                                                            className:
                                                                'text-secondary-light w-auto py-1.5 px-2',
                                                        },
                                                        item: {
                                                            className:
                                                                'text-secondary aria-selected:bg-primary-light p-2',
                                                        },
                                                        list: {
                                                            className: 'py-2',
                                                        },
                                                    }}
                                                />
                                                <ErrorMessage name="role"/>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-5">
                                            <h3 className="text-2xl font-bold">
                                                Threshold / Bonus
                                            </h3>
                                            <div className="flex flex-col gap-1">
                                                <div className="flex flex-col justify-stretch gap-4 sm:flex-row">
                                                    <div className="flex grow flex-col gap-1">
                                                        <label className="text-sm">
                                                            Threshold
                                                        </label>
                                                        <BeInputNumber
                                                            className="w-full"
                                                            placeholder="Enter threshold"
                                                            name="threshold"
                                                            values={values}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex grow flex-col gap-1">
                                                        <label className="text-sm">
                                                            Bonus
                                                        </label>
                                                        <BeInputNumber
                                                            className="w-full"
                                                            placeholder="Enter bonus"
                                                            name="bonus"
                                                            values={values}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            suffix="%"
                                                            min={1}
                                                            max={99}
                                                        />
                                                    </div>
                                                </div>
                                                <ErrorMessage name="threshold"/>
                                                <ErrorMessage name="bonus"/>
                                            </div>
                                        </div>
                                        <BeButton
                                            type="submit"
                                            className="w-32 self-end"
                                            disabled={saving}
                                        >
                                            {saving ? (
                                                <CircleNotch
                                                    size="1.75rem"
                                                    className="animate-spin"
                                                />
                                            ) : (
                                                'Save'
                                            )}
                                        </BeButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="flex-col items-start gap-6 rounded-xl bg-white p-8 shadow-sm">
                        <div className="flex flex-col gap-4">
                            <h3 className="text-2xl font-bold">Subscription FAQ</h3>
                            <ul className="flex flex-col gap-y-2">
                                <li className="flex items-center gap-x-1.5 leading-5">
                                    <AsteriskSimple
                                        size="1.5rem"
                                        weight="bold"
                                        className="shrink-0"
                                    />
                                    Each user is $19.90 per month
                                </li>
                                <li className="flex items-center gap-x-1.5 leading-5">
                                    <AsteriskSimple
                                        size="1.5rem"
                                        weight="bold"
                                        className="shrink-0"
                                    />
                                    Add and remove users any time, no lock-in
                                    contracts
                                </li>
                                <li className="flex items-center gap-x-1.5 leading-5">
                                    <AsteriskSimple
                                        size="1.5rem"
                                        weight="bold"
                                        className="shrink-0"
                                    />
                                    New users will be billed on a pro-rata basis
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
        </>
    );
};

export default UserEditPage;
