import { createContext } from 'react';

import { ConnectedAppsContextValue } from './types';

const ConnectedAppsContext = createContext<ConnectedAppsContextValue>({
    isJobAdderConnected: null,
    isAstuteConnected: null,
    isXeroConnected: null,
    isAllConnected: null,
});

export default ConnectedAppsContext;
