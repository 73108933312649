import {X} from "@phosphor-icons/react";
import {getBaseClassNames, getSizeClassNames, getVariantClassNames} from "../../../core/beUi/BeButton/utils";
import {ErrorMessage, Form, Formik} from "formik";
import {BeButton, BeInputText} from "../../../core/beUi";
import {Dialog} from "primereact/dialog";
import jobAdderValidationSchema from "../validators/jobAdderValidationSchema";
import NewWindow from "react-new-window";
import {useContext, useState} from "react";
import {getAxios, getErrorMessage} from "../../../core/utils";
import {ToastContext} from "../../../core/contexts";
import {useAuth, useToast} from "../../../core/hooks";
import {IntegrationType} from "../../../core/enums/integrationType";


interface JobAdderDialogProps {
    open: boolean;
    onClose():void;
}

const JobAdderDialog = (props: JobAdderDialogProps) => {

    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);
    const [showJobAdderLogin, setShowJobAdderLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const { selectedWorkspace } = useAuth();

    const handleOnClose = () =>{
        setShowJobAdderLogin(false);
        props.onClose();
    }

    const handleSaveDetails =  async ({
                                         clientId,
                                         clientSecret
                                      }: any) => {

        try {
            setLoading(true);
            const axios = await getAxios();

            await axios.post('/api/Integrations/SaveIntegration', {
                clientId,
                clientSecret,
                integrationType: IntegrationType.JobAdder,
                workspaceId: selectedWorkspace?.workspaceId
            }).then(_ => {
                setShowJobAdderLogin(true);
                setLoading(false);
            });


        } catch (error) {
            setLoading(false);
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
        }
    };

    return (
        <>
            {showJobAdderLogin && (
                <NewWindow
                    title="Connect JobAdderCallback Account"
                    url={`https://id.jobadder.com/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_JA_CLIENT_ID}&scope=${process.env.REACT_APP_JA_SCOPE}&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_JA_REDIRECT_URI}`}
                    center="screen"
                    features={{ height: 500, width: 500 }}
                    onUnload={() => handleOnClose()}
                />
            )}
            <Dialog
                header={
                    <h3 className="text-center text-2xl font-bold text-secondary">
                        Enter your JobAdder details
                    </h3>
                }
                closeIcon={<X size="1.75rem" />}
                className="w-auto min-w-48 max-w-xl"
                visible={props.open}
                onHide={() => handleOnClose()}
                draggable={false}
                resizable={false}
                pt={{
                    header: {
                        className:
                        /*tw*/ 'p-6 pb-5 sm:p-8 sm:pb-6 border-b border-solid border-b-brandGray-5 gap-x-12',
                    },
                    closeButton: {
                        className: /*tw*/ `${getBaseClassNames(false)} ${getSizeClassNames('sm', true)} ${getVariantClassNames('ghost')} w-auto h-auto`,
                    },
                    content: {
                        className: /*tw*/ 'p-0',
                    },
                }}
            >
                <Formik
                    initialValues={{ clientId: 'zyff6c55y5mu7pnaznfzlectyi', clientSecret: 'tkt6skduaxxejplg6xuhpy6g44vnu4eov4inyulb6wxpoobosgxu' }}
                    validationSchema={jobAdderValidationSchema}
                    onSubmit={handleSaveDetails}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="flex flex-col items-stretch justify-start gap-y-4 px-6 py-5 sm:px-8 sm:py-6">
                                <div className="flex flex-col gap-1">
                                    <label className="text-sm">Client Id</label>
                                    <BeInputText
                                        className="w-full"
                                        placeholder="Enter your client id"
                                        name="clientId"
                                        values={values}
                                        onChange={handleChange}
                                    />
                                    <ErrorMessage name="clientId" />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label className="text-sm">Client secret</label>
                                    <BeInputText
                                        className="w-full"
                                        type="password"
                                        placeholder="Enter your client secret"
                                        name="clientSecret"
                                        values={values}
                                        onChange={handleChange}
                                    />
                                    <ErrorMessage name="clientSecret" />
                                </div>
                            </div>
                            <div className="flex border-t border-brandGray-5 p-6 pt-5 sm:p-8 sm:pt-6">
                                <BeButton type="submit" size="lg" fullWidth>
                                    Next
                                </BeButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}

export default JobAdderDialog;