import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

let stripe: Stripe | null = null;

const getStripe = async () => {
    if (!stripe)
        stripe = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
        );
    return stripe;
};

export default getStripe;
