import { InputOtp, InputOtpProps } from 'primereact/inputotp';
import { forwardRef, Ref } from 'react';
import { twMerge } from 'tailwind-merge';

import { getSizeClassNames, getThemeClassNames } from './utils';

export type BeInputOtpProps = Omit<InputOtpProps, 'children' | 'type'> & {
    size?: 'sm' | 'md' | 'lg';
};

const BeInputOtp = forwardRef(function BeInputOtp(
    { size = 'md', pt = {}, className, ...rest }: BeInputOtpProps,
    ref: Ref<HTMLInputElement>
) {
    const classNames = twMerge(
        getSizeClassNames(size),
        getThemeClassNames(),
        className
    );

    return (
        <InputOtp
            autoComplete="off"
            ref={ref}
            pt={{
                root: { className: /*tw*/ 'flex-wrap justify-center' },
                input: { root: { className: classNames } },
                ...pt,
            }}
            {...rest}
        />
    );
});

export default BeInputOtp;
