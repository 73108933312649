import Logo from "./common/logo/logo";
import Footer from "./common/footer/footer";
import {Outlet} from "react-router-dom";

const LandingLayout = () => {
    return (
        <>
            <div className="flex flex-1 flex-col items-stretch bg-primary">
                <div className="flex flex-1 flex-col items-center justify-center">
                    <div className="max-w-80 p-8">
                        <Logo />
                    </div>
                    <div className="flex w-full flex-col items-center justify-center">
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default LandingLayout;