import {ProgressSpinner} from 'primereact/progressspinner';
import React, {ComponentType, useContext} from 'react';
import {ConnectedAppsContext} from "../contexts";
import {useNavigate} from "react-router-dom";


const withConnectedApps = <P extends {}>(
    Component: ComponentType<P>,
    LoadingComponent?: ComponentType
): React.ComponentType<any> => {

    return (props: P) => {
        const { isAllConnected } = useContext(ConnectedAppsContext);
        const navigate = useNavigate();

        if (isAllConnected === null) {
            return LoadingComponent ? <LoadingComponent /> : <ProgressSpinner />;
        } else if (isAllConnected) {
            return <Component {...props} />;
        } else {
            navigate('/integrations');
            return null;
        }
    };
};

export default withConnectedApps;
