import {PlacementState} from "./interfaces/placementState";
import {UnknownAction} from "redux";
import * as actionTypes from "../actions";
import {produce} from "immer";
import {PlacementPageLinks} from "./interfaces/placementPageLinks";
import {Placement} from "./interfaces/placement";


const initialState: PlacementState = {
    isLoadingPlacements: false,
    placements: [],
    totalPlacements: 0,
    pageLinks: {
        first: null,
        prev: null,
        next: null,
        last: null
    }
};

const placementsReducer = (state: PlacementState = initialState, action: UnknownAction): PlacementState => {
    switch (action.type) {
        case actionTypes.PLACEMENTS_LOAD:
            return produce(state, draft => {
                draft.isLoadingPlacements = true;
            });
        case actionTypes.PLACEMENTS_LOADED:
            return produce(state, draft => {
                draft.placements = action.placements as Placement[];
                draft.pageLinks = action.pageLinks as PlacementPageLinks;
                draft.totalPlacements = action.totalPlacements as number;
                draft.isLoadingPlacements = false;
            });
        case actionTypes.PLACEMENTS_LOADED_ERROR:
            return produce(state, draft => {
                draft.placements = [];
                draft.isLoadingPlacements = false;
            });
        default:
            return state;
    }

}
export default placementsReducer;