
import {BeLink} from "../../core/beUi";


export default function PageNotFound() {
    return (
        <div className="flex flex-1 flex-col items-stretch bg-primary">
            <div className="flex flex-1 flex-col items-center gap-y-32 p-10 sm:gap-y-48 sm:p-16">
                <div className="flex max-w-80 flex-col space-y-4">
                    <h1 className="text-3xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Oops!</h1>
                    <h3 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">404 - Page not found</h3>
                    <p>The page you are looking for might have been removed, had it's name changed or is temporarily unavailable.</p>
                    <div><BeLink
                        to="/"
                        variant="outlined"
                        size="lg"
                        fullWidth
                        className="max-w-72 shrink border-secondary sm:basis-72"
                    >
                        Return Home
                    </BeLink></div>
                </div>
        </div>
        </div>
    );
}