import axios, { AxiosInstance } from 'axios';
import {fetchAuthSession} from "@aws-amplify/auth";

let axiosInstance: AxiosInstance | null = null;
let tokenId: string | undefined = '';

const getAxios = async (token: string = ''): Promise<AxiosInstance> => {
    if (!axiosInstance || tokenId !== token) {
        const session = await fetchAuthSession();
        const idToken = session?.tokens?.idToken?.toString();
        tokenId = token || idToken;

        axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${tokenId}`,
            },
        });
    }

    return axiosInstance;
};

export default getAxios;
