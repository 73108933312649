import {Outlet, useNavigate} from "react-router-dom";
import {BeButton, BeToast} from "../core/beUi";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { ToastContext, UserContext} from "../core/contexts";
import {useWindowSize} from "usehooks-ts";
import { SignOut, Swap, UserCircle} from "@phosphor-icons/react";
import {signOut} from "aws-amplify/auth";
import Logo from "./common/logo/logo";
import Sidebar from "./sidebar/sidebar";
import {getRoleString} from "../core/utils/getRoleString";

const MainLayout = () => {

    const toastRef = useContext(ToastContext);
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const { userAttributes, currentUser, selectedWorkspace, unsetWorkspaceSelection } = useContext(UserContext);
    const [checkedWorkspace, setCheckedWorkspace] = useState(false);
    const [role, setRole] = useState('');

    const handleSignOut = async () => {
        if(unsetWorkspaceSelection){
            unsetWorkspaceSelection();
        }
        await signOut();
        navigate('/');
    };

    useEffect(() => {
        if (!selectedWorkspace && !!currentUser) {
            navigate('/select-workspace');
            setCheckedWorkspace(false);
        } else {
            if(selectedWorkspace) {
                setRole(getRoleString(selectedWorkspace.role));
            }
        }
        setCheckedWorkspace(true);
    }, [selectedWorkspace]);

    return (
        <>
            <BeToast ref={toastRef} />
            { checkedWorkspace && <div className="flex h-screen w-screen flex-col items-stretch justify-start overflow-hidden">
                <header className="flex h-20 items-center justify-between gap-x-6 bg-primary px-5 py-4">
                    <div className="flex items-center justify-start gap-x-2">
                        <div className="max-h-10 w-10 lg:w-56">
                            <Logo iconOnly={width < 1024}/>
                        </div>
                    </div>
                    <div className="flex items-center justify-start gap-x-2">
                        <BeButton
                            variant="outlined"
                            className="border-secondary bg-secondary text-sm text-white"
                            onClick={() => navigate('/billing')}
                        >
                            30 days remaining
                        </BeButton>

                        <BeButton
                            size="sm"
                            variant="ghost"
                            className="hover:bg-primary-light hover:text-secondary"
                            menu={[
                                {
                                    label: 'Workspaces',
                                    icon: <Swap size="1.75rem"/>,
                                    command: () => navigate('/select-workspace'),
                                },
                                {
                                    label: 'Logout',
                                    icon: <SignOut size="1.75rem"/>,
                                    command: () => handleSignOut(),
                                },
                            ]}
                        >
                            <div className="flex items-center gap-x-2">
                                <UserCircle size="2rem" weight="fill"/>
                                <div>{`${userAttributes?.given_name} ${userAttributes?.family_name}`} <span
                                    className="font-light">({role})</span></div>
                            </div>
                        </BeButton>
                    </div>
                </header>
                <div className="flex min-h-0 flex-1">
                <Sidebar />
                    <div className="h-full grow overflow-y-auto bg-brandGray-6 p-6 scrollbar-thin">
                        <Outlet />
                    </div>
                </div>
            </div> }
        </>
    )
}

export default MainLayout;