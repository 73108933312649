
import { useContext, useState } from 'react';

import { ToastContext } from '../../../core/contexts';
import { useToast } from '../../../core/hooks';

import StepOne from './stepOne/stepOne';
import StepTwo from './stepTwo/stepTwo';
import { SignUpForm, Steps } from './types';
import { handleSignUp } from './utils';
import {useNavigate} from "react-router-dom";
import {PhoneNumberFormat as PNF} from "google-libphonenumber";
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const initialValues: SignUpForm = {
    firstName: '',
    lastName: '',
    email: '',
    code: '',
    phoneNumber: '',
    country: '',
    workspaceName: '',
};

export default function SignUpPage() {
    const navigate = useNavigate();
    const toastRef = useContext(ToastContext);
    const [values, setValues] = useState<SignUpForm>(initialValues);
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const { showToast } = useToast(toastRef);
    const handleNext = () => currentStep < 2 && setCurrentStep(currentStep + 1);
    const handlePrev = () => currentStep > 1 && setCurrentStep(currentStep - 1);

    const onSuccess = () => {
        showToast({
            severity: 'success',
            summary: 'success',
            detail: 'You have created an account successfully!',
        });
       navigate('/sign-in');
    };

    const onFailure = (error: Error) =>
        showToast({
            severity: 'error',
            summary: 'failed',
            detail:
                error.name === 'UsernameExistsException'
                    ? 'Email address is already registered!'
                    : error.message,
        });

    const steps: Steps<typeof values> = [
        {
            component: StepOne,
            props: {
                loading,
                initialValues: values,
                handleBack: () => navigate('/'),
                handleSubmit: (values) => {
                    setValues((prevValues) => ({ ...prevValues, ...values }));
                    handleNext();
                },
            },
        },
        {
            component: StepTwo,
            props: {
                loading,
                initialValues: values,
                handleBack: (values) => {
                    setValues((prevValues) => ({ ...prevValues, ...values }));
                    handlePrev();
                },
                handleSubmit: async ({ country, phoneNumber, ...rest }) => {
                    setValues((prevValues) => ({ ...prevValues, ...values }));
                    setLoading(true);
                    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
                    await handleSignUp(
                        {
                            country,
                            phoneNumber: phoneUtil.format(number, PNF.E164),
                            ...rest,
                        },
                        onSuccess,
                        onFailure
                    );
                    setLoading(false);
                },
            },
        },
    ];

    const { component: CurrentStep, props } = steps[currentStep - 1];

    return <CurrentStep {...props} />;
}
