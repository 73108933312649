import { AuthUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import UserContext from './UserContext';
import { getCurrentUser, getCurrentUserAttributes } from './utils';
import {UserAttributes, WorkspaceRole} from './types';
import {WORKSPACE_SESSION_KEY} from "../../utils/contstants";

const UserContextProvider = ({
    children,
}: Readonly<{
    children: ReactNode;
}>) => {
    const [currentUser, setCurrentUser] = useState<AuthUser | null | undefined>(null);
    const [userAttributes, setUserAttributes] = useState<UserAttributes | null | undefined>(null);
    const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceRole | null | undefined>(null);
    const [isLoading, setIsLoading] = useState(true);

    const refreshUserAttributes = useCallback(
        async () => setUserAttributes(await getCurrentUserAttributes()),
        []
    );

    const unsetWorkspaceSelection = () => {
        sessionStorage.removeItem(WORKSPACE_SESSION_KEY);
        setSelectedWorkspace(null);
    }

    const selectWorkspace = (workspaceId: number) => {
        const ws = userAttributes?.roles?.find(w => w.workspaceId === workspaceId);
        sessionStorage.setItem(WORKSPACE_SESSION_KEY, JSON.stringify(ws));
        setSelectedWorkspace(ws);
    }

    useEffect(() => {
        const updateUser = async () => {
            const data = JSON.parse(sessionStorage.getItem(WORKSPACE_SESSION_KEY) as string);
            setIsLoading(true);
            setSelectedWorkspace(data || null);
            setCurrentUser(await getCurrentUser());
            setUserAttributes(await getCurrentUserAttributes());
            setIsLoading(false);
        };
        const hubListenerRemove = Hub.listen('auth', updateUser);
        updateUser();
        return () => hubListenerRemove();
    }, []);

    return (
        <UserContext.Provider
            value={{
                currentUser,
                userAttributes,
                isLoading,
                refreshUserAttributes,
                selectedWorkspace,
                selectWorkspace,
                unsetWorkspaceSelection
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
