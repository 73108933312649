import {UserRole} from "../enums/userRole";


export function getRoleString(role: UserRole): string {
    const roleStrings: Record<UserRole, string> = {
        [UserRole.SUPER_ADMIN]: 'Super Admin',
        [UserRole.ADMIN]: 'Admin',
        [UserRole.NATIONAL_MANAGER]: 'National Manager',
        [UserRole.TEAM_MANAGER]: 'Team Manager',
        [UserRole.RECRUITER]: 'Recruiter',
        [UserRole.RESOURCER]: 'Resourcer',
    };

    return roleStrings[role];
}