import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const DashboardLoading = () => {
    const items = Array(5).fill(undefined);
    return (
        <div className="flex flex-col gap-y-6">
            <h2 className="mb-4 text-4xl font-bold text-black">Progress</h2>
            <div className="flex flex-wrap justify-stretch gap-6">
                <div className="flex flex-1 basis-1/4 flex-col items-start justify-start gap-y-6 rounded-2xl bg-white p-6 shadow-sm">
                    <div className="flex w-full items-center gap-x-2">
                        <Skeleton
                            size="3rem"
                            shape="circle"
                            className="shrink-0"
                        />
                        <Skeleton />
                    </div>
                    <Skeleton height="3rem" />
                </div>
                <div className="flex flex-1 basis-1/4 flex-col items-start justify-start gap-y-6 rounded-2xl bg-white p-6 shadow-sm">
                    <div className="flex w-full items-center gap-x-2">
                        <Skeleton
                            size="3rem"
                            shape="circle"
                            className="shrink-0"
                        />
                        <Skeleton />
                    </div>
                    <Skeleton height="3rem" />
                </div>
                <div className="flex flex-1 basis-1/4 flex-col items-start justify-start gap-y-6 rounded-2xl bg-white p-6 shadow-sm">
                    <div className="flex w-full items-center gap-x-2">
                        <Skeleton
                            size="3rem"
                            shape="circle"
                            className="shrink-0"
                        />
                        <Skeleton />
                    </div>
                    <Skeleton height="3rem" />
                </div>
            </div>
            <div className="rounded-2xl border border-solid bg-white p-4">
                <div className="flex flex-col gap-y-2">
                    <div className="flex items-center justify-between gap-x-6">
                        <Skeleton height="1.75rem" width="6.25rem" />
                        <div className="flex gap-x-2">
                            <Skeleton height="1.5rem" width="1.75rem" />
                            <Skeleton height="1.5rem" width="1.75rem" />
                            <Skeleton height="1.5rem" width="1.75rem" />
                            <Skeleton height="1.5rem" width="1.75rem" />
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-x-6">
                        <Skeleton height="2.5rem" width="3.5rem" />
                        <Skeleton height="1.75rem" width="2.5rem" />
                    </div>
                </div>
                <Skeleton className="mt-2 min-h-96" />
            </div>
            <div className="rounded-2xl bg-white p-6 shadow-sm">
                <div className="mb-5 flex items-center justify-between gap-x-6">
                    <h2 className="text-2xl font-bold text-secondary">
                        Latest placements
                    </h2>
                    <Skeleton height="1.25rem" width="3rem" />
                </div>
                <div className="overflow-x-auto scrollbar-thin">
                    <div className="inline-block min-w-full align-middle">
                        <DataTable
                            value={items}
                            rows={5}
                            tableClassName="min-w-full max-w-max"
                        >
                            <Column
                                field="candidateName"
                                header="Candidate Name"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="jobTitle"
                                header="Job Title"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="company"
                                header="Company"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="type"
                                header="Type"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="recruiter"
                                header="Recruiter"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="bonus"
                                header="Bonus"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="split"
                                header="Split"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="startDate"
                                header="Start Date"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="invoiceId"
                                header="INV-#"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="invoiceDue"
                                header="Invoice Due"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                            <Column
                                field="invoiceStatus"
                                header="Invoice Status"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                body={<Skeleton height="2rem" />}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLoading;
