import {X} from "@phosphor-icons/react";
import {getBaseClassNames, getSizeClassNames, getVariantClassNames} from "../../../core/beUi/BeButton/utils";
import {ErrorMessage, Form, Formik} from "formik";
import astuteValidationSchema from "../validators/astuteValidationSchema";
import {BeButton, BeInputText} from "../../../core/beUi";
import {Dialog} from "primereact/dialog";


interface AstuteDialogProps {
    open: boolean;
    onClose():void;
}

const AstuteDialog = (props: AstuteDialogProps) => {

    const handleOnClose = () =>{
        props.onClose();
    }

    const handleAstuteCredentials = () => {
        handleOnClose();
    };

    return (
        <Dialog
            header={
                <h3 className="text-center text-2xl font-bold text-secondary">
                    Enter Your Astute Credentials
                </h3>
            }
            closeIcon={<X size="1.75rem" />}
            className="w-auto min-w-48 max-w-xl"
            visible={props.open}
            onHide={() => handleOnClose()}
            draggable={false}
            resizable={false}
            pt={{
                header: {
                    className:
                    /*tw*/ 'p-6 pb-5 sm:p-8 sm:pb-6 border-b border-solid border-b-brandGray-5 gap-x-12',
                },
                closeButton: {
                    className: /*tw*/ `${getBaseClassNames(false)} ${getSizeClassNames('sm', true)} ${getVariantClassNames('ghost')} w-auto h-auto`,
                },
                content: {
                    className: /*tw*/ 'p-0',
                },
            }}
        >
            <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={astuteValidationSchema}
                onSubmit={handleAstuteCredentials}
            >
                {({ values, handleChange }) => (
                    <Form>
                        <div className="flex flex-col items-stretch justify-start gap-y-4 px-6 py-5 sm:px-8 sm:py-6">
                            <div className="flex flex-col gap-1">
                                <label className="text-sm">Username</label>
                                <BeInputText
                                    className="w-full"
                                    placeholder="Enter astute username"
                                    name="username"
                                    values={values}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="username" />
                            </div>
                            <div className="flex flex-col gap-1">
                                <label className="text-sm">Password</label>
                                <BeInputText
                                    className="w-full"
                                    type="password"
                                    placeholder="Enter astute password"
                                    name="password"
                                    values={values}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="password" />
                            </div>
                        </div>
                        <div className="flex border-t border-brandGray-5 p-6 pt-5 sm:p-8 sm:pt-6">
                            <BeButton type="submit" size="lg" fullWidth>
                                Connect
                            </BeButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default AstuteDialog;