export const getSizeClassNames = (size: 'sm' | 'md' | 'lg') => {
    switch (size) {
        case 'sm':
            return /*tw*/ 'py-0.5 px-2.5 text-xs';
        case 'md':
            return /*tw*/ 'py-1 px-3 text-xs';
        case 'lg':
            return /*tw*/ 'py-1.5 px-3.5 text-xs';
        default:
            return /*tw*/ 'py-0.5 px-2.5 text-xs';
    }
};

export const getVariantClassNames = (
    variant: 'filled' | 'outlined',
    severity?: 'success' | 'info' | 'warning' | 'danger' | null
) => {
    const isFilled = variant === 'filled';
    switch (severity) {
        case 'info':
            return /*tw*/ `text-cyan-600 ${isFilled ? 'bg-cyan-100' : 'bg-transparent border border-cyan-600'}`;
        case 'success':
            return /*tw*/ `text-green-600 ${isFilled ? 'bg-green-100' : 'bg-transparent border border-green-600'}`;
        case 'danger':
            return /*tw*/ `text-red-600 ${isFilled ? 'bg-red-100' : 'bg-transparent border border-red-600'}`;
        case 'warning':
            return /*tw*/ `text-yellow-600 ${isFilled ? 'bg-yellow-100' : 'bg-transparent border border-yellow-600'}`;
        default:
            return /*tw*/ `text-stone-600 ${isFilled ? 'bg-stone-100' : 'bg-transparent border border-stone-600'}`;
    }
};
