import Footer from "./common/footer/footer";
import {Outlet} from "react-router-dom";

const EmptyLayout = () => {
    return (
        <>
            <div className="flex flex-1 flex-col items-stretch bg-primary">
                <div className="flex flex-1 flex-col items-center justify-center">
                    <div className="flex w-full flex-col items-center justify-center">
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default EmptyLayout;