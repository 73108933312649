import { useLayoutEffect, useState } from 'react';
import { CircleNotch } from '@phosphor-icons/react';
import { useCountdown } from 'usehooks-ts';
import {BeButton, BeInputOtp} from "../../../core/beUi";



type OtpCodeProps = {
    recipient: string;
    recipientType?: 'email' | 'phone';
    resendCode: () => void;
    isResendDisabled: boolean;
    handleNext: (otp: string) => Promise<void>;
    handleBack: () => void;
};
const OtpCode = ({
                     recipient,
                     recipientType = 'email',
                     resendCode,
                     isResendDisabled,
                     handleNext,
                     handleBack,
                 }: OtpCodeProps) => {
    const [otp, setOtp] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [count, { startCountdown }] = useCountdown({
        countStart: 60,
        intervalMs: 1000,
    });
    useLayoutEffect(() => {
        !count && setDisabled(false);
    }, [count]);

    useLayoutEffect(() => {
        startCountdown();
    }, [startCountdown]);

    const handleVerify = async () => {
        setVerifyLoading(true);
        await handleNext(otp);
        setVerifyLoading(false);
    };

    const hiddenRecipient =
        recipientType === 'email'
            ? recipient.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2')
            : recipient.replace(/(\d\D?){6}$/, (x) => x.replace(/\d/g, '*'));

    return (
        <div className="flex flex-col items-center gap-y-6">
            <div className="rounded-sm bg-white">
                <h3 className="border-b border-brandGray-5 p-6 pb-5 text-center text-2xl font-bold sm:p-8 sm:pb-6">
                    Enter code sent to {hiddenRecipient}
                </h3>
                <div className="flex flex-col items-stretch justify-start gap-y-4 px-8 py-6">
                    <BeInputOtp
                        value={otp}
                        onChange={(e: any) => setOtp(e.value as string)}
                        integerOnly
                        length={6}
                        autoFocus={true}
                        pt={{
                            root: {
                                className: 'gap-x-4 flex-wrap justify-center',
                            },
                            input: {
                                root: {
                                    className:
                                        'text-5xl text-black font-semibold px-4.5 py-12 hover:border-secondary focus:ring-2 focus:ring-secondary/50 focus:border-secondary w-16 rounded-xl',
                                },
                            },
                        }}
                    />
                </div>
                <div className="flex flex-col justify-stretch gap-x-6 gap-y-4 border-t border-brandGray-5 p-6 pt-5 sm:flex-row sm:p-8 sm:pt-6">
                    <BeButton
                        size="lg"
                        variant="outlined"
                        fullWidth
                        onClick={handleBack}
                    >
                        Back
                    </BeButton>
                    <BeButton
                        size="lg"
                        fullWidth
                        disabled={verifyLoading || otp.length !== 6}
                        onClick={handleVerify}
                    >
                        {verifyLoading ? (
                            <CircleNotch
                                size="1.75rem"
                                className="animate-spin"
                            />
                        ) : (
                            'Continue'
                        )}
                    </BeButton>
                </div>
            </div>
            <p>
                Didn’t get a code?{' '}
                <BeButton
                    link
                    className="inline p-0 underline underline-offset-4"
                    disabled={disabled || isResendDisabled}
                    onClick={resendCode}
                >
                    {disabled
                        ? `Send again in ${count} seconds`
                        : 'Send another code...'}
                </BeButton>
            </p>
        </div>
    );
};

export default OtpCode;
