

const Footer = () => {
    return (
        <footer className="flex justify-center border-t border-secondary/15 p-3 text-sm sm:p-4 sm:text-base">
            <p>
                Support and feedback:{' '}
                <a
                    href="mailto:help@workingbe.com"
                    target="_blank"
                    className="underline underline-offset-4"
                >
                    help@workingbe.com
                </a>
            </p>
        </footer>
    );
};

export default Footer;
