import {CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight, DownloadSimple} from '@phosphor-icons/react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {useEffect, useState} from 'react';

import { BeBadge, BeButton, BeInputText } from '../../core/beUi';
import { withConnectedApps } from '../../core/hoc';

import PlacementsLoading from './placementsLoading';
import {AppDispatch, RootState} from "../../core/state/store";
import {useDispatch, useSelector} from "react-redux";
import {getPlacements} from "../../core/state/placements/placementEffects";
import {PlacementsTable} from "./interfaces/placementsTable";
import { DateTime } from "luxon";
import {useAuth} from "../../core/hooks";
import {WorkspaceRole} from "../../core/contexts/User/types";

const InvoiceStatusTag = ({ invoiceStatus }: any) => (
    <>
    { invoiceStatus && <BeBadge
        severity={invoiceStatus === 'Paid' ? 'success' : 'danger'}
        variant="filled"
        value={invoiceStatus}
        /> }
    </>
);

const PlacementsPage = () => {

    const placements = useSelector((state: RootState) => state.placements?.placements);
    const pageLinks = useSelector((state: RootState) => state.placements?.pageLinks);
    const isLoading = useSelector((state: RootState) => state.placements?.isLoadingPlacements);
    const [selectedRows, setSelectedRows] = useState<any>(null);
    const [rows, setRows] = useState<PlacementsTable[]>([]);
    const dispatch: AppDispatch = useDispatch();
    const { selectedWorkspace } = useAuth();

    const handlePaging = (pageLink: string|null = '') => {
        if (selectedWorkspace) {
            dispatch(getPlacements(pageLink || undefined, selectedWorkspace));
        }
    }

    useEffect(() => {
            handlePaging();
    }, [selectedWorkspace]);

    useEffect(() => {
        if(placements && placements.length) {
            const r = placements.map(p => ({
                candidateName: p.candidateName,
                jobTitle: p.jobTitle,
                company: p.company,
                jobType: p.jobType,
                split: `${p.splitPercentage}%`,
                billing: `${p.billingAmount}%`,
                margin: `${p.agencyMarginPercentage}%`,
                startDate: DateTime.fromISO(p.startDate).toLocaleString(DateTime.DATE_MED),
                invoiceNumber: p.invoiceNumber,
                invoiceStatus: '-'
            }));
            setRows(r);
        }
    }, [placements]);

    return (
        <>
            { isLoading && <PlacementsLoading /> }
            { !isLoading && <div className="flex flex-col gap-y-6">
            <h2 className="mb-4 text-4xl font-bold text-black">Placements</h2>
            <div className="flex items-center justify-between gap-x-6">
                <div className="p-inputgroup w-auto">
                    <BeInputText placeholder="Search..." />
                    <BeButton
                        variant="outlined"
                        className="rounded-l-none border-l-0 bg-gray-200"
                    >
                        Search
                    </BeButton>
                </div>
                <BeButton>
                    <DownloadSimple size="1.75rem" />
                    Export
                </BeButton>
            </div>
            <div className="overflow-x-auto scrollbar-thin">
                <div className="inline-block min-w-full align-middle">
                    <DataTable
                        selectionMode={null}
                        selection={selectedRows}
                        onSelectionChange={(e) => setSelectedRows(e.value)}
                        value={rows}
                        tableClassName="min-w-full max-w-max"
                    >
                        <Column
                            selectionMode="multiple"
                            headerClassName="w-8 bg-white"
                        />
                        <Column
                            field="candidateName"
                            header="Candidate Name"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal"
                        />
                        <Column
                            field="jobTitle"
                            header="Job Title"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal"
                        />
                        <Column
                            field="company"
                            header="Company"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal"
                        />
                        <Column
                            field="jobType"
                            header="Type"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                        />
                        <Column
                            field="split"
                            header="Split"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            align="center"
                        />
                        <Column
                            field="billing"
                            header="Billing"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            align="center"
                        />
                        <Column
                            field="margin"
                            header="Margin"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            align="center"
                        />
                        <Column
                            field="startDate"
                            header="Start Date"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                        />
                        <Column
                            field="invoiceNumber"
                            header="INV-#"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                        />
                        <Column
                            field="invoiceStatus"
                            header="Invoice Status"
                            headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                            bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                            align="center"
                           // body={InvoiceStatusTag}
                            body={<>&mdash;</>}
                        />
                    </DataTable>
                </div>
                <div className="flex items-center justify-center space-x-3 p-4">
                    <BeButton variant="outlined" className="rounded-full" disabled={!pageLinks?.first} onClick={() => handlePaging(pageLinks?.first)}>
                        <CaretDoubleLeft size="1.2rem" />
                    </BeButton>
                     <BeButton variant="outlined" className="rounded-full" disabled={!pageLinks?.prev} onClick={() => handlePaging(pageLinks?.prev)}>
                        <CaretLeft size="1.2rem" />
                    </BeButton>
                    <BeButton variant="outlined" className="rounded-full" disabled={ !pageLinks?.next } onClick={() => handlePaging(pageLinks?.next)}>
                        <CaretRight size="1.2rem" />
                    </BeButton>
                    <BeButton variant="outlined" className="rounded-full" disabled={ !pageLinks?.last } onClick={() => handlePaging(pageLinks?.last)}>
                        <CaretDoubleRight size="1.2rem"  />
                    </BeButton>
                </div>
            </div>
        </div> }
        </>
    );
};

//export default withConnectedApps(PlacementsPage, PlacementsLoading);
export default PlacementsPage;