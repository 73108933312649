import { isMobilePhone } from 'validator';
import { object, string } from 'yup';
import { BeMessage } from '../../../../core/beUi';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const validationSchema = object().shape({
    firstName: string().required(() => (
        <BeMessage severity="error" text="First name is required" />
    )),
    lastName: string().required(() => (
        <BeMessage severity="error" text="Last name is required" />
    )),
    email: string()
        .email(() => <BeMessage severity="error" text="Email is invalid" />)
        .required(() => (
            <BeMessage severity="error" text="Email is required" />
        )),
    phoneNumber: string()
        .test({
            message: () => (
                <BeMessage severity="error" text="Phone number is invalid" />
            ),
            test: (value, context) => {
                const country = context.parent.country.toUpperCase();
                return phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, country), country);
            },
        })
        .required(() => (
            <BeMessage severity="error" text="Phone number is required" />
        )),
    country: string()
        .oneOf(['AU', 'NZ'])
        .required(() => (
            <BeMessage severity="error" text="Please choose a country" />
        )),
});

export default validationSchema;
